import React, { useEffect, useState } from 'react';
import { FaTags, FaTshirt } from 'react-icons/fa';


const GET_CAT_API_BASE_URL = 'https://ecommmerceapi.hindustanrides.in/api/v1/';

function AnimatedCounter({ value }) {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(prevCounter => {
        const increment = Math.ceil((value - prevCounter) / 5);
        const nextCounter = prevCounter + increment;
        return nextCounter >= value ? value : nextCounter;
      });
    }, 100);

    return () => clearInterval(interval);
  }, [value]);

  return <span>{counter}</span>;
}

function Home() {
  const [data, setData] = useState([]);
  const [animateCounters, setAnimateCounters] = useState(false);
  const [subcatgoryData, setsubcategoryData] = useState([])
  
  useEffect(() => {
   
    fetchGetadataa()
  },[data]);

  const fetchGetadataa = () =>{
    fetch(`${GET_CAT_API_BASE_URL}/alldatacount`)
    .then(response => response.json())
    .then(data => {
      console.log('data =>', data);
      setData(data);
      setAnimateCounters(true); // Trigger animation after data is fetched
    })
    .catch(error => console.error('API call error:', error));
  }

  useEffect(() => {
        fetch(`http://localhost:4800/api/v1/subcategorydashboardCount`)
      .then(response => response.json())
      .then(data => {
      
        setsubcategoryData(data.data.subcategoryCount);
     
        setAnimateCounters(true); // Trigger animation after data is fetched
      })
      .catch(error => console.error('API call error:', error));
   
  }, []);


  return (
    <main className="main-container">
      <div className="p-5">
        <div className="main-cards">
          <div className={`card ${animateCounters ? 'counter-animation' : ''}`} style={{backgroundColor:"gray"}}>
            <div className="card-inner">
              <h3 className="font-medium text-lg">Category</h3>
              <FaTags className="card_icon" />
            </div>
            <h1 className="font-medium text-xl">
            {animateCounters ? <AnimatedCounter value={data.totalCategories} /> : data.totalCategories}
            </h1>
          </div>
          <div className={`card ${animateCounters ? 'counter-animation' : ''}`}  style={{backgroundColor:"gray"}}>
            <div className="card-inner">
              <h3 className="font-medium text-lg">Sub Category</h3>
              <FaTags className="card_icon" />
            </div>
            <h1 className="font-medium text-xl">
              {animateCounters ? <AnimatedCounter value={data.totalSubcategories} /> : data.totalSubcategories}
            </h1>
          </div>

          <div className={`card ${animateCounters ? 'counter-animation' : ''}`}  style={{backgroundColor:"gray"}}>
            <div className="card-inner">
              <h3 className="font-medium text-lg">Product</h3>
              <FaTshirt className="card_icon" />
            </div>
            <h1 className="font-medium text-xl">
              {animateCounters ? <AnimatedCounter value={data.totalProducts} /> : data.totalProducts}
            </h1>
          </div>

          {/* {subcatgoryData.map((subcategory, index) => (
            <div key={index} className={`card ${animateCounters ? 'counter-animation' : ''}`}>
              <div className="card-inner">
                <h3 className="font-medium text-lg text-red-700">{subcategory.subcategoryName}</h3>
                <h3 className="font-medium text-lg text-red-700">{subcategory.count}</h3>
              </div>
            </div>
          ))} */}



        </div>
        <h3 className="font-medium text-lg text-indigo-600">Subcategory Count List : </h3>
        <div className='main-container'>
          <div className="main-cards">
            {subcatgoryData.sort().map((subcategory, index) => (
              <div key={index} className={`card ${animateCounters ? 'counter-animation' : ''}`}  style={{backgroundColor:"#7B68EE"}}>
                <div className="card-inner">
                  <h3 className="font-medium text-lg">{subcategory.subcategoryName}</h3>
                  <FaTshirt className="card_icon" />
                </div>
                <h1 className="font-medium text-xl ">
                  {animateCounters ? <AnimatedCounter value={subcategory.count} /> : subcategory.count}
                </h1>
              </div>
            ))}
          </div>
        </div>



      </div>
    </main>
  );
}

export default Home;
