import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const GET_CAT_API_BASE_URL = 'https://ecommmerceapi.hindustanrides.in/api/v1';

const CategoryModal = ({ catImg, setCatImg, isDarkMode, isOpen, toggleModal, setCategories, categories, isEditMode, categoryToEdit, existingCategories }) => {
  const [categoryName, setCategoryName] = useState('');
  const [categoryImg, setCategoryImg] = useState('');
  const [existingImg, setExistingImage] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isEditMode && categoryToEdit) {
      setCategoryName(categoryToEdit.MaincategoryName);
      setCategoryImg(categoryToEdit.categoryImg);
    } else {
      setCategoryName('');
      setCatImg('');
    }
  }, [isEditMode, categoryToEdit]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setCategoryImg(file);
    const imageUrl = URL.createObjectURL(file);
    setExistingImage(imageUrl);
  };

  const isDuplicateCategoryName = (name) => {
    return categories.some((category) => category.MaincategoryName === name);
  };

  const validateForm = () => {
    const errors = {};
  
    if (!categoryName.trim()) {
      errors.MaincategoryName = 'Category Name is required.';
    } else if (!isEditMode && isDuplicateCategoryName(categoryName)) {
      errors.MaincategoryName = 'Category with this name already exists.';
    } else if (isEditMode && categoryToEdit && categoryToEdit.MaincategoryName !== categoryName && isDuplicateCategoryName(categoryName)) {
      errors.MaincategoryName = 'Category with this name already exists.';
    }
  
    if (!isEditMode && !categoryImg) {
      errors.categoryImg = 'Category Img is required.';
    }
  
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  

  const handleChange = (e) => {
    setCategoryName(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, MaincategoryName: '' }));
  };

  // const handleSaveChanges = (e) => {
  //   e.preventDefault();

  //   if (!validateForm()) {
  //     console.error('Form validation failed.');
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append('MaincategoryName', categoryName);
  //   formData.append('categoryImg', categoryImg);

  //   const apiUrl = isEditMode
  //     ? `${GET_CAT_API_BASE_URL}/updatecategory/${categoryToEdit.id}`
  //     : `${GET_CAT_API_BASE_URL}/addcategory`;

  //   const method = isEditMode ? 'PATCH' : 'POST';

  //   fetch(apiUrl, {
  //     method,
  //     body: formData,
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (data.status === 'error') {
  //       } else {
  //         console.log(data.message);
  //         if (isEditMode) {
  //           setCategories((prevCategories) =>
  //             prevCategories.map((category) =>
  //               category.id === categoryToEdit.id ? data.result : category
  //             )
  //           );
  //         } else {
  //           setCategories((prevCategories) => [...prevCategories, data.result]);
  //         }
  //         toggleModal();
  //         console.log(isEditMode ? 'Category updated successfully' : null);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  // };

  const handleSaveChanges = (e) => {
    e.preventDefault();
  
    if (!validateForm()) {
      console.error('Form validation failed.');
      return;
    }
  
    const formData = new FormData();
    formData.append('MaincategoryName', categoryName);
    formData.append('categoryImg', categoryImg);
  
    const apiUrl = isEditMode
      ? `${GET_CAT_API_BASE_URL}/updatecategory/${categoryToEdit.id}`
      : `${GET_CAT_API_BASE_URL}/addcategory`;
  
    const method = isEditMode ? 'PATCH' : 'POST';
  
    fetch(apiUrl, {
      method,
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === 'error') {
          toast.error('Error:', data.message);
        } else {
          if (isEditMode) {
            setCategories((prevCategories) => [
              data.result,
              ...prevCategories.filter((category) => category.id !== categoryToEdit.id),
            ]);
          } else {
            setCategories((prevCategories) => [data.result, ...prevCategories]);
          }
          toggleModal();
          toast.success(isEditMode ? 'Category updated successfully' : 'Category added successfully');
        }
      })
      .catch((error) => {
        toast.error('Error:', error);
      }).finally(() => {
        // Close the modal regardless of success or failure
        toggleModal();
      });
  };

  
  return (
    <div>
      {isOpen ? (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-[20%] my-6 mx-auto">
            <div className="md:w-max sm:w-max border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className={`text-3xl font-semibold ${isDarkMode ? 'dark' : 'text-black'}`}>
                  {isEditMode ? 'Edit Category' : 'Add Category'}
                </h3>

                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={toggleModal}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              <div className="relative p-6 flex-auto">
                <div className="mb-4 rounded">
                  <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                    Category Name:
                  </label>
                  <input
                    type="text"
                    id="categoryName"
                    name="MaincategoryName"
                    value={categoryName}
                    onChange={handleChange}
                    className={`w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none ${
                      errors.MaincategoryName ? 'border-red-500' : ''
                    } ${isDarkMode ? 'text-black' : 'text-black'}`}
                    placeholder="Enter category name"
                  />
                  {errors.MaincategoryName && (
                    <div className="text-red-500">{errors.MaincategoryName}</div>
                  )}
                </div>
              </div>
              <div className="mb-4 rounded ml-4">
                <label htmlFor="categoryImg" className="block text-gray-600 font-semibold mb-2">
                  Category Img:
                </label>
                <input
                  type="file"
                  id="categoryImg"
                  name="categoryImg"
                  onChange={(event) => {
                    handleImageChange(event);
                  }}
                  className={`w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none ${
                    errors.categoryImg ? 'border-red-500' : ''
                  } ${isDarkMode ? 'text-black' : 'text-black'}`}
                />
                {errors.categoryImg && <div className="text-red-500">{errors.categoryImg}</div>}
              </div>
            
              {isEditMode && (
                <div className="mt-2 px-6">
                  <label className="block text-gray-600 font-semibold mb-2">Existing Image:</label>
                  <img
                    src={existingImg||`https://ecommmerceapi.hindustanrides.in/uploads/${catImg}`}
                    alt="Existing Subcategory Image"
                    className="w-20 h-20"
                  />
                </div>
              )}

              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
                <button
                  className="bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {isOpen && <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>}
    </div>
  );
};

export default CategoryModal;
