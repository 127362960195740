import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GET_CAT_API_BASE_URL = 'https://ecommmerceapi.hindustanrides.in/api/v1';

const SubCategoryModal = ({
  isOpen,
  subtoggleModal,
  selectedOption,
  setSelectedOption,
  editItemId,
  isDarkMode,
  subcategoryName,
  setSubcategoryName,
  isEditMode,
  subCategories,
  setSubCategories, subCatEditImg, setSubCatEditImg, onReloadData, setSizeImg, sizeImg
}) => {
  const [categories, setCategories] = useState([]);
  const [categoryError, setCategoryError] = useState('');
  const [subcategoryError, setSubcategoryError] = useState('');
  const [subcategoryImgError, setSubcategoryImgError] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [isCategoryValid, setIsCategoryValid] = useState(true);
  const [isSubcategoryValid, setIsSubcategoryValid] = useState(true);
  const [previewImage, setPreviewImage] = useState(null);
  const [sizepreviewImage, setSizePreviewImage] = useState(null);
  // const [subCatImg, setSubImg] = useState('');


  const fetchAllCategories = async () => {
    try {
      const response = await fetch(`${GET_CAT_API_BASE_URL}/getallcategory`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCategories(data.category);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  useEffect(() => {
    if (isEditMode) {
      setSubcategoryName(subcategoryName);
    }
  }, [isEditMode, subcategoryName]);


  const isSubcategoryNameValid = (name) => {
    if (!isEditMode && !name) {
      setSubcategoryError('Please enter a subcategory name.');
      return false;
    }

    if (!isEditMode) {
      const subcategoryExists = subCategories.some((subCategory) =>
        subCategory.SubCateoryName.toLowerCase() === name.toLowerCase() &&
        subCategory.mainCategoryName === selectedOption
      );

      if (subcategoryExists) {
        setSubcategoryError('Subcategory with this name already exists in the selected category.');
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCategoryError('');
    setSubcategoryError('');


    let isValid = true;

    if (!isEditMode) {
      if (!selectedCategoryId) {
        setCategoryError('Please select a category.');
        isValid = false;
      }
      if (!subcategoryName) {
        setSubcategoryError('Please enter a subcategory name.');
        isValid = false;
      }
      if (!isSubcategoryNameValid(subcategoryName)) {
        isValid = false;
      }
      if (!subCatEditImg) {
        setSubcategoryImgError('Please enter a subcategory Image.');
        isValid = false;
      }
    }

    if (isEditMode) {
      if (!selectedOption && !subcategoryName && !subCatEditImg) {
        setCategoryError('Please select a category.');
        setSubcategoryError('Please enter a subcategory name.');
        isValid = false;
      } else {
        if (!selectedOption) {

          setCategoryError('Please select a category.');
          isValid = false;
        }
        if (!subcategoryName) {
          setSubcategoryError('Please enter a subcategory name.');
          isValid = false;
        }
        if (!subCatEditImg) {
          setSubcategoryImgError('Please enter a subcategory Image.');
          isValid = false;
        }
      }
      const existingCategory = subCategories.find(category => category.id === editItemId);
      const isDataChanged = existingCategory.SubCateoryName !== subcategoryName || existingCategory.mainCategoryName !== selectedOption;

      if (isDataChanged) {
        const isDuplicate = subCategories.some(
          (subCategory) =>
            subCategory.mainCategoryName === selectedOption &&
            subCategory.SubCateoryName === subcategoryName
        );

        if (isDuplicate) {
          setSubcategoryError('Subcategory with this name already exists in the selected category.');
          isValid = false;
        }
      }



    }

    const isDuplicate = subCategories.some(
      (subCategory) =>
        subCategory.mainCategoryName === selectedOption &&
        subCategory.SubCateoryName === subcategoryName
    );

    if (isDuplicate && !isEditMode) {
      setSubcategoryError('Subcategory with this name already exists in the selected category.');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      const url = isEditMode
        ? `${GET_CAT_API_BASE_URL}/updatesubcategiry/${editItemId}`
        : `${GET_CAT_API_BASE_URL}/addSubcategory/${selectedCategoryId}`;

      const formData = new FormData();
      formData.append('SubCateoryName', subcategoryName);
      formData.append('mainCategoryName', selectedOption);
      formData.append('subCatImg', subCatEditImg);
      formData.append('sizeImg', sizeImg);

      const response = await fetch(url, {
        method: isEditMode ? 'PATCH' : 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      toast.success(data.message);
      setSelectedOption('');
      setSubcategoryName('');
      // setSubCatEditImg('');
      if (isEditMode) {
        const updatedSubCategories = subCategories.map((subCategory) =>
          subCategory.id === editItemId
            ? { ...subCategory, SubCateoryName: subcategoryName, mainCategoryName: selectedOption, subCatImg: subCatEditImg }
            : subCategory
        );
        setSubCategories(updatedSubCategories);

      } else {
        setSubCategories((prevCategories) => [...prevCategories, data.result]);
      }
      onReloadData();
      setSelectedOption('');
      setSubcategoryName('');
      setPreviewImage(null);
      subtoggleModal();
    } catch (error) {
      console.error('Error:', error);
    }
  };



  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   setSubCatEditImg(file);
  //   const imageUrl = URL.createObjectURL(file);
  //   setPreviewImage(imageUrl);
  // };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSubCatEditImg(file);
    setSizeImg(file);
    
    const imageUrl = URL.createObjectURL(file);
    console.log('imageUrl =>',imageUrl);
    
    setPreviewImage(imageUrl);

  }; 

  const handleSizeImageChange = (e) => {
    const file = e.target.files[0];
    setSizeImg(file);
  
    const imageUrl = URL.createObjectURL(file);
    console.log('imageUrl =>',imageUrl);
    
    setSizePreviewImage(imageUrl);

  }; 

  const closeModal = () => {
    setSelectedOption('');
    setSubcategoryName('');
    setCategoryError('');
    setSubCatEditImg('');
    setSubcategoryError('');
    setSubcategoryImgError('')
    setPreviewImage(null);
    setIsCategoryValid(true);
    setIsSubcategoryValid(true);
    subtoggleModal();
  };

  return (
    <>
      {isOpen ? (
        <>

          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-[30%] my-6 mx-auto">
              <div className=" md:w-max sm:w-max border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">

                  <h3 className={`text-3xl font-semibold ${isDarkMode ? 'dark' : 'text-black'}`}>
                    {isEditMode ? 'Edit Sub Category' : 'Add Sub Category'}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={closeModal}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <form>
                    <div className="mb-4 rounded">
                      <label
                        htmlFor="categoryName"
                        className="block text-gray-600 font-semibold mb-2"
                      >
                        Select Category:
                      </label>
                      <select
                        id="categories"
                        name="categoryId"
                        className={`border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-indigo-500 w-full ${!isCategoryValid ? 'border-red-500' : ''
                          } ${isDarkMode ? 'dark' : 'text-black'}`}
                        value={selectedOption}
                        onChange={(e) => {
                          setSelectedOption(e.target.value);
                          const category = categories.find((cat) => cat.MaincategoryName === e.target.value);
                          setSelectedCategoryId(category ? category.id : '');
                        }}
                      >
                        <option value="">Choose a category</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.MaincategoryName}>
                            {category.MaincategoryName}
                          </option>
                        ))}
                      </select>

                      {categoryError && (
                        <p className="text-red-500">{categoryError}</p>
                      )}
                    </div>

                    <div className="mb-4 rounded">
                      <label
                        htmlFor="categoryName"
                        className="block text-gray-600 font-semibold mb-2"
                      >
                        Sub Category:
                      </label>
                      <input
                        type="text"
                        id="categoryName"
                        name="subcategoryName"
                        className={`w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-indigo-500 ${!isSubcategoryValid ? 'border-red-500' : ''
                          } ${isDarkMode ? 'dark' : 'text-black'}`}
                        placeholder="Enter category name"
                        value={subcategoryName}
                        onChange={(e) => setSubcategoryName(e.target.value)}
                      />
                      {subcategoryError && (
                        <p className="text-red-500">{subcategoryError}</p>
                      )}
                    </div>


                    <div className="mb-4 rounded">
                      <label
                        htmlFor="categoryName"
                        className="block text-gray-600 font-semibold mb-2"
                      >
                        Sub Category Image:
                      </label>
                      <input
                        type="file"
                        id="image"
                        name="setSubImg"
                        className={`w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-indigo-500 ${!isSubcategoryValid ? 'border-red-500' : ''
                          } ${isDarkMode ? 'dark' : 'text-black'}`}
                        onChange={handleImageChange}
                      />

                      {subcategoryImgError && (
                        <p className="text-red-500">{subcategoryImgError}</p>
                      )}

                      {isEditMode && (
                        <div className="mt-2">
                          <label className="block text-gray-600 font-semibold mb-2">
                            Existing Image:
                          </label>

                          <img
                            src={previewImage || `https://ecommmerceapi.hindustanrides.in/uploads/${subCatEditImg}`}
                            alt="Existing Subcategory Image"
                            className="w-20 h-20"
                          />
                        </div>
                      )}
                    </div>

                    {/* size add  */}

                    <div className="mb-4 rounded">
                      <label
                        htmlFor="sizeImage"
                        className="block text-gray-600 font-semibold mb-2"
                      >
                        Size Image:
                      </label>
                      <input
                        type="file"
                        id="sizeImage"
                        name="sizeImg"
                        className={`w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-indigo-500 ${!isSubcategoryValid ? 'border-red-500' : ''
                          } ${isDarkMode ? 'dark' : 'text-black'}`}
                        onChange={handleSizeImageChange}
                      />

                      {subcategoryImgError && (
                        <p className="text-red-500">{subcategoryImgError}</p>
                      )}

                      {isEditMode && (
                        <div className="mt-2">
                          <label className="block text-gray-600 font-semibold mb-2">
                            Existing Size Image:
                          </label>

                          <img
                            src={sizepreviewImage || `https://ecommmerceapi.hindustanrides.in/uploads/${sizeImg}`}
                            alt="Existing Size Image"
                            className="w-20 h-20"
                          />
                        </div>
                      )}
                    </div>

                  </form>

                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    {!isEditMode ? (
                      <>
                        <button
                          className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Save Changes
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Update
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isOpen && <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>}
        </>
      ) : null}
    </>
  );
};

export default SubCategoryModal;