import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaTrash, FaEdit, FaCaretUp, FaCaretDown } from 'react-icons/fa';
import BannerProductModal from './BannerProductModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { IoEyeSharp } from "react-icons/io5";
import Zoom from 'react-image-zoom';

const GET_CAT_API_BASE_URL = 'https://ecommmerceapi.hindustanrides.in/api/v1';

const BannerProduct = ({ isDarkMode, setIsDarkMode }) => {

    const [proShowModal, setProShowModal] = useState(false);
    const [productData, setProduct] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [idSortDirection, setIdSortDirection] = useState('asc');
    const [categoryNameSortDirection, setCategoryNameSortDirection] = useState('asc');
    const [subcategoryNameSortDirection, setSubCategoryNameSortDirection] = useState('asc');
    const [pnamecategoryNameSortDirection, setPnameCategoryNameSortDirection] = useState('asc');
    const [priceSortDirection, setPriceSortDirection] = useState('asc');
    const [searchQueryName, setSearchQueryName] = useState('');
    const [searchQueryPrice, setSearchQueryPrice] = useState('');
    const [searchDiscountPrice, setSearchDisCountPrice] = useState('');
    const [searchCategoty, setSearchCategory] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editedProduct, setEditedProduct] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedCatOption, setSelectedCatOption] = useState('');
    const [searchSubcatgory, setSearchSubcategory] = useState('');
    const [openModel, setOpenModel] = useState(false);
    const [selectView, setSelectView] = useState(null)
    const [zoomedImage, setZoomedImage] = useState(null);


    const [inputValue, setInputValue] = useState('');
    const [editItemId, setEditItemId] = useState(null);

    const [selectedProducts, setSelectedProducts] = useState([]);

    const shoproShowModal = () => {
        setProShowModal(false);
        setEditMode(false)
        setSelectedOption('')
        setSelectedCatOption('')
    }

    const handleImageClick = (image) => {
        setZoomedImage(image);
    };

    const handleCloseZoom = () => {
        setZoomedImage(null);
    };


    const fetchProduct = (page, limit = 10) => {
        fetch(`${GET_CAT_API_BASE_URL}/getBannerAllProduct/${page}?limit=${limit}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setProduct(data.result);
                setCurrentPage(data.page);
                setTotalPages(data.totalPages);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        fetchProduct(currentPage);
    }, [currentPage]);

    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1);
    };

    //delete product 
    const deleteProduct = (_id) => {

        fetch(`${GET_CAT_API_BASE_URL}/deleteBannerProduct/${_id}`, {
            method: 'DELETE',
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {

                if (data.status === 'success') {
                    toast.success('Product deleted successfully');

                    const updatedProduct = productData.filter((item) => item._id !== data.result._id);
                    setProduct(updatedProduct);
                } else {
                    // console.error(`Failed to delete product with proId: ${subCategoryId}`);
                    console.error(data);
                    toast.error(data.message || 'An error occurred while deleting the product');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                toast.error('An error occurred while processing your request');
            });
    };


      //sorting
      const idWise = (sortBy) => {
        const sortedCategories = [...productData];
        console.log(sortedCategories)
        const isDescending = sortBy === 'desc';

        sortedCategories.sort((a, b) => {
            const idA = parseFloat(a.subCategoryId.match(/\d+/)[0]);
            const idB = parseFloat(b.subCategoryId.match(/\d+/)[0]);

            if (isDescending) {
                return idB - idA;
            } else {
                return idA - idB;
            }
        });

        setProduct(sortedCategories);
        setIdSortDirection(isDescending ? 'asc' : 'desc');
    };

    const categoryNameWise = (sortBy) => {
        const sortedCategories = [...productData];
        const isDescending = sortBy === 'desc';

        sortedCategories.sort((a, b) => {
            if (isDescending) {
                return b.bannerPName.localeCompare(a.bannerPName);
            } else {
                return a.bannerPName.localeCompare(b.bannerPName);
            }
        });

        setProduct(sortedCategories);
        setSubCategoryNameSortDirection(isDescending ? 'asc' : 'desc');
    };

    const categoryPriceWise = (sortBy) => {
        const sortedProducts = [...productData];
        const isDescending = sortBy === 'desc';

        sortedProducts.sort((a, b) => {
            const priceA = parseFloat(a.price);
            const priceB = parseFloat(b.price);

            if (isDescending) {
                return priceB - priceA;
            } else {
                return priceA - priceB;
            }
        });

        setProduct(sortedProducts);
        setPriceSortDirection(isDescending ? 'asc' : 'desc');
    };

    const categorydetailsWise = (sortBy) => {
        const sortedCategories = [...productData];
        const isDescending = sortBy === 'desc';

        sortedCategories.sort((a, b) => {
            if (isDescending) {
                return b.details.localeCompare(a.details);
            } else {
                return a.details.localeCompare(b.details);
            }
        });

        setProduct(sortedCategories); // Use setSubCategories to update the state
        setCategoryNameSortDirection(isDescending ? 'asc' : 'desc');
    };

   

    const categorydiscountPriceWise = (sortBy) => {
        const sortedProducts = [...productData];
        const isDescending = sortBy === 'desc';

        sortedProducts.sort((a, b) => {
            const priceA = parseFloat(a.discountPrice);
            const priceB = parseFloat(b.discountPrice);

            if (isDescending) {
                return priceB - priceA;
            } else {
                return priceA - priceB;
            }
        });

        setProduct(sortedProducts);
        setCategoryNameSortDirection(isDescending ? 'asc' : 'desc');
    };

    const categorySubcategoryNameWise = (sortBy) => {
        const sortedProducts = [...productData];
        const isDescending = sortBy === 'desc';

        sortedProducts.sort((a, b) => {
            if (isDescending) {
                return b.subcategoryName.localeCompare(a.subcategoryName);
            } else {
                return a.subcategoryName.localeCompare(b.subcategoryName);
            }
        });

        setProduct(sortedProducts);
        setPnameCategoryNameSortDirection(isDescending ? 'asc' : 'desc');
    };


    const handleEdit = (_id) => {
        setEditItemId(_id);
        const itemToEdit = productData.find((item) => item._id === _id);
        console.log(itemToEdit)
        setSelectedOption(itemToEdit.subcategoryName);
        setSelectedCatOption(itemToEdit.MaincategoryName)
        setInputValue(itemToEdit.subcategoryName);
        // setEditItemId(itemToEdit.bannerPImg)
        setProShowModal(true);
        setEditMode(true)
        setEditedProduct(itemToEdit)
    }

    const handleView = (_id) => {
        setEditItemId(_id);
        const itemToEdit = productData.find((item) => item._id === _id);
        setOpenModel(true)
        setSelectView(itemToEdit)
        console.log(selectView)
        setZoomedImage(null)
    }

    const clearSearch = () => {
        setSearchCategory('');
        setSearchSubcategory('');
        setSearchQueryName('');
        setSearchQueryPrice('');
        setSearchDisCountPrice('');
        fetchProduct();
    }

  return (
        <div className="flex flex-col main-container px-4 overflow-hidden text-sm ">
            <div className="">
                <div className="">
                    <div className="flex justify-between items-center border-b mt-[4rem] md:mt-[1rem] sm:mt-[1rem]">
                        <h1 className={`text-xl px-4 mb-4 category-title ${isDarkMode ? 'dark-mode ' : 'dashbg'} font-semibold`}>
                          Banner  Product
                        </h1>
                        <Link>
                            <button
                                className="bg-blue-500 cursor-pointer  text-white font-semibold px-6 py-2 rounded-lg mb-6 hover:bg-blue-600 focus:outline-none"
                                onClick={() => { setProShowModal(true) }}
                            >
                                Add
                            </button>
                        </Link>
                    </div>
                </div>

                <div className="max-w-full mx-auto ">
                    <div className="flex flex-col mt-[-34px]">
                        <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 my-10">
                            <div className="align-middle inline-block w-full rounded-xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] ">
                               
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between -mt-10 py-3" aria-label="Table navigation">
                <span className="text-sm font-normal mb-4 md:mb-0 block w-full md:inline md:w-auto text-black text-center">
                    Showing rows
                    <span className="font-semibold font-semibold text-[#7B68EE] rounded-full bg-gray-300 px-1  ml-2 mr-1">
                        {(currentPage - 1) * 10 + 1}
                    </span>
                    to
                    <span className="font-semibold font-semibold text-[#7B68EE] rounded-full bg-gray-300 px-1  ml-1">
                        {(currentPage - 1) * 10 + 10}
                    </span>
                </span>


                <div className="py-4 pr-4 w-auto justify-content float-right">
                    <ReactPaginate
                        previousLabel={"←"}
                        nextLabel={" →"}
                        pageCount={totalPages}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active "}
                    />
                </div>
            </nav> */}


            <div className="relative overflow-auto shadow-md">
                <div className="">
                    <table className="border min-w-full divide-y divide-gray-200 w-full text-sm text-left rtl:text-right text-gray-500 sticky top-0 bg-white ">
                        <thead className="sticky top-[-2px] text-center text-xs text-white bg-[#7B68EE]">
                            <tr className='text-center'>
                                <th scope="col" className="px-6 py-3 text-center flex">

                                    No
                                    <button
                                        className="ml-2 text-white hover:text-white"
                                        onClick={() => idWise(idSortDirection)}
                                    >
                                        {idSortDirection === 'asc' ? <FaCaretUp /> : <FaCaretDown />}
                                    </button>

                                </th>
                                <th scope="col" className="px-6 py-3 text-center">

                                    <span className='flex'>
                                        Category
                                        <button
                                            className="ml-2 text-white hover:text-white"
                                            onClick={() => categorySubcategoryNameWise(categoryNameSortDirection)}
                                        >
                                            {categoryNameSortDirection === 'asc' ? <FaCaretUp /> : <FaCaretDown />}
                                        </button>
                                    </span>



                                </th>
                                <th scope="col" className="px-6 py-3 text-center">
                                    <span className='flex'>

                                        Subcategory
                                        <button
                                            className="ml-2 text-white hover:text-white"
                                            onClick={() => categorySubcategoryNameWise(pnamecategoryNameSortDirection)}
                                        >
                                            {pnamecategoryNameSortDirection === 'asc' ? <FaCaretUp /> : <FaCaretDown />}
                                        </button>
                                    </span>
                                </th>
                                <th scope="col" className="px-6 py-3 text-center">

                                    Product Name
                                    <button
                                        className="ml-2 text-white hover:text-white"
                                        onClick={() => categoryNameWise(subcategoryNameSortDirection)}
                                    >
                                        {subcategoryNameSortDirection === 'asc' ? <FaCaretUp /> : <FaCaretDown />}
                                    </button>

                                </th>
                                <th scope="col" className="px-6 py-3 text-center">
                                    <span className='flex'>

                                        Price
                                        <button
                                            className="ml-2 text-white hover:text-white"
                                            onClick={() => categoryPriceWise(priceSortDirection)}
                                        >
                                            {priceSortDirection === 'asc' ? <FaCaretUp /> : <FaCaretDown />}
                                        </button>
                                    </span>
                                </th>
                                <th scope="col" className="px-6 py-3 text-center">
                                    Size
                                </th>
                               
                                <th scope="col" className="px-6 py-3 text-center">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-center bg-white border-b bg-white hover:border-gray-200 hover:bg-gray-50  max-h-[500px]'>


                            {productData.length === 0 ? (
                                <tr>
                                    <td colSpan="7" className="text-center py-4">
                                        No products found.
                                    </td>
                                </tr>
                            ) : (
                                productData.map((el, i) => (
                                    console.log(el),
                                    <tr 
                                        className={`text-center bg-white border-b ${editItemId === el._id ? 'bg-[#ffcccc]' : 'bg-white'
                                            }`}
                                        key={i + 1}>
                                        <td className="px-6 py-4">
                                            {(currentPage - 1) * 10 + i + 1}
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="leading-5 text-gray-900">
                                                {el && el.MaincategoryName ? el.MaincategoryName : 'N/A'}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="leading-5 text-gray-900">
                                                {el && el.subcategoryName ? el.subcategoryName : 'N/A'}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="leading-5 text-gray-900">
                                                {el.bannerPName}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="leading-5 text-gray-900">
                                                {el.price}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="leading-5 text-gray-900">
                                                {el.bannerPImg.length}
                                            </div>
                                        </td>

                                        <td className="px-4 py-4 bg-white-200 text-center ">
                                            <div className="leading-5 text-gray-900 flex space-x-6 justify-center items-center">
                                                <IoEyeSharp size={20} fill='#3B82F9' onClick={() => handleView(el._id)} className='cursor-pointer' />
                                                <FaEdit size={20} fill='green' onClick={() => handleEdit(el._id)} className='cursor-pointer' />
                                                <FaTrash size={20} fill='red' onClick={() => deleteProduct(el._id)} className='cursor-pointer' />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>

                </div>
            </div>
            
            {openModel ? (
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative  my-6 mx-auto ">
                        <div className="md:w-max sm:w-max border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3 className={`text-xl text-center font-semibold ${isDarkMode ? 'dark' : 'text-black'} `}>
                                    Product Detail
                                </h3>


                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setOpenModel(false)}
                                >
                                    <span className="text-gray  h-6 w-6 text-2xl block ">
                                        ×
                                    </span>
                                </button>
                            </div>

                            <div className='px-9 py-4 border-b border-solid border-slate-200 '>
                                <table className="w-full mb-4  border border-solid border-slate-200">
                                    <tbody className='px-4 text-black'>
                                        <tr className=''>
                                            <td className="font-semibold py-2 px-4">MaincategoryName:</td>
                                            <td>{selectView.MaincategoryName}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-semibold py-2 px-4">SubcategoryName:</td>
                                            <td>{selectView.subcategoryName}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-semibold py-2 px-4">Name:</td>
                                            <td>{selectView.bannerPName}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-semibold py-2 px-4">Price:</td>
                                            <td>{selectView.price}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-semibold py-2 px-4">Discount Price:</td>
                                            <td>{selectView.discountPrice}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-semibold py-2 px-4">Rating:</td>
                                            <td>{selectView.rating}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-semibold py-2 px-4">Size:</td>
                                            <td>
                                                {selectView.size.length > 0 ? (
                                                    selectView.size.map((el, index) => (
                                                        <span key={index} className='text-black mr-2'>
                                                            {el}
                                                        </span>
                                                    ))
                                                ) : (
                                                    <span className='text-red-700 font-semibold'>Not selected</span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-semibold py-2 px-4">Bottom Size:</td>
                                            <td>
                                                {selectView.bottomSize.length > 0 ? (
                                                    selectView.bottomSize.map((el, index) => (
                                                        <span key={index} className='text-black mr-2'>
                                                            {el ? el : <span className='text-red-700 font-semibold'>Not selected</span>}
                                                        </span>
                                                    ))
                                                ) : (
                                                    <span className='text-red-700 font-semibold'>Not selected</span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-semibold py-2 px-4">Shoes Size:</td>
                                            <td>
                                                {selectView.shoesSize.length > 0 ? (
                                                    selectView.shoesSize.map((el, index) => (
                                                        <span key={index} className='text-black mr-2'>
                                                            {el ? el : <span className='text-red-700 font-semibold'>Not selected</span>}
                                                        </span>
                                                    ))
                                                ) : (
                                                    <span className='text-red-700 font-semibold'>Not selected</span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-semibold py-2 px-4">Product Images:</td>
                                            <td className='flex '>

                                                {selectView.bannerPImg.map((el, index) => (
                                                    console.log(selectView.bannerPImg),
                                                    <div key={index} className='' onClick={() => handleImageClick(el)}>
                                                        <img src={`https://ecommmerceapi.hindustanrides.in/uploads/${el}`} className='w-20 h-20' alt={`Product Image ${index}`} />
                                                    </div>
                                                ))}


                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {zoomedImage && (
                                <div className="overlay" onClick={handleCloseZoom}>
                                    <div className="modal">
                                        <div className="relative">
                                            <img src={`https://ecommmerceapi.hindustanrides.in/uploads/${zoomedImage}`} className="mx-auto w-[46%]" alt="Zoomed Image" />
                                            <button className="close-btn absolute top-0 right-[1rem] m-4 text-white font-bold bg-black px-1 rounded" onClick={handleCloseZoom}>
                                                X
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}


                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => setOpenModel(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                                    type="submit"
                                    onClick={() => setOpenModel(false)}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}


            {openModel && <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>}
            {proShowModal && (
                <BannerProductModal
                    updateProductData={(newProductData) => setProduct(newProductData)}
                    editedProduct={editedProduct}
                    setSelectedOption={setSelectedOption}
                    selectedOption={selectedOption}
                    setEditMode={setEditMode}
                    editMode={editMode}
                    shoproShowModal={shoproShowModal}
                    // renderData={renderData}
                    setEditItemId={setEditItemId}
                    setProShowModal={setProShowModal}
                    proShowModal={proShowModal}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    productData={productData}
                    setSelectedCatOption={setSelectedCatOption}
                    selectedCatOption={selectedCatOption}
                />
            )}
        </div>
  )
}

export default BannerProduct