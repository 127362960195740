import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GET_CAT_API_BASE_URL = 'https://ecommmerceapi.hindustanrides.in/api/v1';

const CategoryModal = ({setSelectedCatOption,selectedCatOption, setSubcategoryName, subcategoryName, selectedOption, setSelectedOption, catImg, setCatImg, isDarkMode, isOpen, toggleModal, setCategories, categories, isEditMode, categoryToEdit, existingCategories }) => {
  const [categoryName, setCategoryName] = useState('');
  const [categoryImg, setCategoryImg] = useState('');
  const [existingImg, setExistingImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [product, setProduct] = useState([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState('');



  console.log(categoryToEdit)
  useEffect(() => {
    if (isEditMode && categoryToEdit) {
      setCategoryImg(categoryToEdit.categoryImg);
      setSelectedMainCategory(categoryToEdit.MaincategoryName);
    } else {
      setCatImg('');
    }
  }, [isEditMode, categoryToEdit]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setCategoryImg(file);
    const imageUrl = URL.createObjectURL(file);
    setExistingImage(imageUrl);
  };

console.log("product",product)
  const validateForm = () => {
    const errors = {};

    if (!categoryImg) {
      errors.categoryImg = 'Banner Img is required.';
    }
    if (!subcategoryName) {
      errors.subcategoryName = 'Subcategory is required.';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const handleSaveChanges = (e) => {
    e.preventDefault();
    e.preventDefault();

    // Validate the form
    if (!validateForm()) {
      // The form is not valid, handle accordingly (e.g., show an error message)
      console.error('Form validation failed');
      return;
    }


    const formData = new FormData();
    formData.append('bannerImg', categoryImg);
    formData.append('MaincategoryName', selectedMainCategory);

    formData.append('subcategoryName', subcategoryName);
    
    const subCatId = selectedCategoryId;
    console.log(subCatId)

    const apiUrl = isEditMode
      ? `${GET_CAT_API_BASE_URL}/editBanner/${categoryToEdit._id}`
      : `${GET_CAT_API_BASE_URL}/addbanner/${subCatId}`;

    const method = isEditMode ? 'PATCH' : 'POST';

    fetch(apiUrl, {
      method,
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === 'error') {
          toast.error("error", data.message)

        } else {
          if (isEditMode) {
            setCategories((prevCategories) => [
              data.result,
              ...prevCategories.filter((category) => category._id !== categoryToEdit._id),
            ]);
          } else {
            setCategories((prevCategories) => [data.result, ...prevCategories]);
          }
          toggleModal();
          toast.success(isEditMode ? 'Banner updated successfully' : 'Banner added successfully');
        }
      })
      .catch((error) => {
        toast.error("error", error)
      });
  };



  // select handelsubcategory
  const fetchAllCategories = async () => {
    try {
      const response = await fetch(`${GET_CAT_API_BASE_URL}/getallcategory`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data)
      setProduct(data.category);
      setCategoriesData(data.subCategory);
    } catch (error) {
      console.error('Error:', error);
    }
  };


  useEffect(() => {
    fetchAllCategories();
  }, []);

  return (
    <div>
      {isOpen ? (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-[20%] my-6 mx-auto">
            <div className="md:w-max sm:w-max border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className={`text-3xl font-semibold ${isDarkMode ? 'dark' : 'text-black'}`}>
                  {isEditMode ? 'Edit Banner' : 'Add Banner'}
                </h3>

                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={toggleModal}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>


              <div className="mb-4 rounded px-4 py-2">
                <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                  Category:
                </label>
                <select
                  className={`w-full border border-gray-300 rounded-lg px-4 py-2.5 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'}`}
                  value={selectedMainCategory}
                  onChange={(e) => setSelectedMainCategory(e.target.value)}
                >
                  <option value="">Select a category</option>
                  {product.map((category) => (
                    <option key={category.id} value={category.MaincategoryName}>
                      {category.MaincategoryName}
                    </option>
                  ))}
                </select>
                {errors.subcategoryName && <p className="text-red-500">{errors.subcategoryName}</p>}
              </div>

              <div className="mb-4 rounded px-4 py-2">
                <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                  Sub Category:
                </label>
                <select
                  className={`w-full border border-gray-300 rounded-lg px-4 py-2.5 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'}`}
                  value={subcategoryName}
                  onChange={(e) => {
                    setSubcategoryName(e.target.value);
                    const category = categoriesData.find((cat) => cat.SubCateoryName === e.target.value);
                    setSelectedCategoryId(category ? category.id : '');
                  }}
                >
                  <option value="">Select a subcategory</option>
                  {categoriesData.map((category) => (
                    <option key={category.id} value={category.SubCateoryName}>
                      {category.SubCateoryName}
                    </option>
                  ))}
                </select>

                {errors.subcategoryName && <p className="text-red-500">{errors.subcategoryName}</p>}
              </div>


              <div className="mb-4 rounded px-4 py-2">
                <label htmlFor="categoryImg" className="block text-gray-600 font-semibold mb-2">
                  Banner Img:
                </label>
                <input
                  type="file"
                  id="categoryImg"
                  name="categoryImg"
                  onChange={(event) => {
                    handleImageChange(event);
                  }}
                  className={`w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none ${errors.categoryImg ? 'border-red-500' : ''
                    } ${isDarkMode ? 'text-black' : 'text-black'}`}
                />
                {errors.categoryImg && <div className="text-red-500">{errors.categoryImg}</div>}
              </div>

              {isEditMode && (
                <div className="mt-2 px-6">
                  <label className="block text-gray-600 font-semibold mb-2">Existing Image:</label>
                  <img
                    src={existingImg || `https://ecommmerceapi.hindustanrides.in/uploads/banner/${catImg}`}
                    alt="Existing Subcategory Image"
                    className="w-20 h-20"
                  />
                </div>
              )}

              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
                <button
                  className="bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {isOpen && <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>}
    </div>
  );
};

export default CategoryModal;
