import React from 'react'
import { FaTags, FaTshirt } from 'react-icons/fa'
import { Link, Outlet, useLocation } from 'react-router-dom';
import Logo from '../images/24325541_2022_ani_cartoon_27-removebg-preview.png';
import { BiSolidDashboard } from 'react-icons/bi';
import sidelogo from '../images/hiya (1).png'
import { IoImageSharp } from "react-icons/io5";


function Sidebar({ openSidebarToggle, OpenSidebar }) {
    const location = useLocation();
    const handleLinkClick = () => {
        
        if (openSidebarToggle) {
            OpenSidebar();
        }
    };

    return (
        <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive" : ""}>
            <div className="flex flex-col w-[220px] shadow-[rgba(0,_0,_0,_0.25)_0px_25px_50px_-12px] h-screen overflow-x">
                <button className="relative text-sm focus:outline-none group">
                    <div className="flex items-center justify-center w-full text-red-900 text-2xl h-16 px-4 border-gray-300 hover:bg-gray-300">
                        <span className="">
                            <img src={sidelogo} className="w-[8rem] mx-auto" />
                        </span>
                    </div>
                </button>

                <div className="flex flex-col flex-grow overflow-auto">
                    <div className='flex mt-5'>
                        <header>
                            <div className="flex mb-2 ">
                                <a className="relative  mr-2 " href="#0">

                                    <Link to="/dashboard">
                                        <img className="rounded-full mt-3 ml-5  " src={Logo} width="56" height="20" alt="User 01" />
                                    </Link>
                                </a>
                                <div className="mt-5 pr-2 p-2">
                                    <a className="inline-flex text-gray-800 hover:text-gray-900" href="#0">
                                        <h2 className="text-[0.9rem] leading-snug justify-center font-semibold text-[#333]">
                                            HIYA FASHION
                                        </h2>
                                    </a>
                                </div>
                            </div>
                        </header>

                    </div>

                    <div className='mt-3'>
                        <div className={`flex items-center p-3 space-x-3 group  text-lg ${location.pathname === '/dashboard' ? 'bg-[#7B68EE] box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; text-black bg-[#7B68EE] border-r-4 border-[#4b0082] text-white' : '  '}`}>
                            <BiSolidDashboard size={25} />
                            <Link to='/dashboard' onClick={handleLinkClick}> Dashboard  </Link>
                        </div>
                        <div className={`flex items-center p-3 space-x-3 group   text-lg ${location.pathname === '/category' ? 'bg-[#7B68EE] box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; text-black bg-[#7B68EE] border-r-4 border-[#4b0082] text-white' : '  '}`}>
                            <FaTags size={20} />
                            <Link to='/category' onClick={handleLinkClick}>Category</Link>
                        </div>
                        <div className={`flex items-center p-3 space-x-3 group  text-lg ${location.pathname === '/subcategory' ? 'bg-[#7B68EE] box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; text-black bg-[#7B68EE] border-r-4 border-[#4b0082] text-white' : '  '}`}>
                            <FaTags size={20} />
                            <Link to='/subcategory' onClick={handleLinkClick}>Sub Category</Link>
                        </div>
                        <div className={`flex items-center p-3 space-x-3 group  text-lg ${location.pathname === '/getproduct' ? 'bg-[#7B68EE] box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; text-black bg-[#7B68EE] border-r-4 border-[#4b0082] text-white' : '  '}`}>
                            <FaTshirt size={20} />
                            <Link to='/getproduct' onClick={handleLinkClick}>Product</Link>
                        </div>
                        <div className={`flex items-center p-3 space-x-3 group  text-lg ${location.pathname === '/order' ? 'bg-[#7B68EE] box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; text-black bg-[#7B68EE] border-r-4 border-[#4b0082] text-white' : '  '}`}>
                            <FaTshirt size={20} />
                            <Link to='/getorder' onClick={handleLinkClick}>Order</Link>
                        </div>
                        <div className={`flex items-center p-3 space-x-3 group  text-lg ${location.pathname === '/getbanner' ? 'bg-[#7B68EE] box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; text-black bg-[#7B68EE] border-r-4 border-[#4b0082] text-white' : '  '}`}>
                            <IoImageSharp size={20} />
                            <Link to='/getbanner' onClick={handleLinkClick}>Banner</Link>
                        </div>
                        {/* <div className={`flex items-center p-3 space-x-3 group  text-lg ${location.pathname === '/getbannerproduct' ? 'bg-[#7B68EE] box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; text-black bg-[#7B68EE] border-r-4 border-[#4b0082] text-white' : '  '}`}>
                            <IoImageSharp size={20} />
                            <Link to='/getbannerproduct' onClick={handleLinkClick}> Banner Product</Link>
                        </div> */}
                        <div className={`flex items-center p-3 space-x-3 group  text-lg ${location.pathname === '/getshipping' ? 'bg-[#7B68EE] box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; text-black bg-[#7B68EE] border-r-4 border-[#4b0082] text-white' : '  '}`}>
                            <IoImageSharp size={20} />
                            <Link to='/getshipping' onClick={handleLinkClick}>User Detail</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Outlet />
        </aside>

    )
}

export default Sidebar
