import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaEdit, FaCaretUp, FaCaretDown, FaTrash } from 'react-icons/fa';
import SubCategoryModal from './SubCategoryModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
const GET_CAT_API_BASE_URL = 'https://ecommmerceapi.hindustanrides.in/api/v1';



const SubCategoryGet = ({ isDarkMode, setIsDarkMode }) => {
    const [subShowModal, setSubShowModal] = useState(false);
    const [subCategories, setSubCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [categories, setCategories] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [categoryToEdit, setCategoryToEdit] = useState(null);
    const [idSortDirection, setIdSortDirection] = useState('asc');
    const [categoryNameSortDirection, setCategoryNameSortDirection] = useState('asc');
    const [searchQueryName, setSearchQueryName] = useState('');
    const [subcategoryName, setSubcategoryName] = useState('');
    const [mainCategory, setMaincategory] = useState('');
    const [subCatEditImg, setSubCatEditImg] = useState('')
    const [reloadData, setReloadData] = useState(false);

    const [sizeImg, setSizeImg] = useState('');

    const [formErrors, setFormErrors] = useState({
        mainCategory: '',
        searchQueryName: '',
    });



    const [editItemId, setEditItemId] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const [inputValue, setInputValue] = useState('');


    const subtoggleModal = () => {
        setSubShowModal(!subShowModal);
        setIsEditMode(false);
        setCategoryToEdit(null);
    };

    const handleReloadData = () => {
        setReloadData(!reloadData);
    };

    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1);
    };

    const handleEdit = (id) => {
        const itemToEdit = subCategories.find((item) => item.id === id);
        console.log("itemToEdit", itemToEdit)
        if (itemToEdit) {
            setEditItemId(id);
            setSelectedOption(itemToEdit.mainCategoryName);
            setInputValue(itemToEdit.mainCategoryName);
            setSubcategoryName(itemToEdit.SubCateoryName);
            setSubCatEditImg(itemToEdit.subCatImg)
            setSizeImg(itemToEdit.sizeImg)
            setSubShowModal(true);
            setIsEditMode(true);
        }
    };

    const fetchSubCategories = (page = 1, limit = 5) => {
        fetch(`${GET_CAT_API_BASE_URL}/getsubcategory/${page}?limit=${limit}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setSubCategories(data.result);
                console.log(data.result)
                setCurrentPage(data.page);
                setTotalPages(data.totalPages);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const idWise = (sortBy) => {
        const sortedCategories = [...subCategories];
        const isDescending = sortBy === 'desc';

        sortedCategories.sort((a, b) => {
            const idA = parseFloat(a.id.match(/\d+/)[0]);
            const idB = parseFloat(b.id.match(/\d+/)[0]);

            if (isDescending) {
                return idB - idA;
            } else {
                return idA - idB;
            }
        });

        setSubCategories(sortedCategories);
        setIdSortDirection(isDescending ? 'asc' : 'desc');
    };

    const categoryNameWise = (sortBy) => {
        const sortedCategories = [...subCategories];
        const isDescending = sortBy === 'desc';

        sortedCategories.sort((a, b) => {
            if (isDescending) {
                return b.SubCateoryName.localeCompare(a.SubCateoryName);
            } else {
                return a.SubCateoryName.localeCompare(b.SubCateoryName);
            }
        });

        setSubCategories(sortedCategories);
        setCategoryNameSortDirection(isDescending ? 'asc' : 'desc');
    };

    useEffect(() => {
        fetchSubCategories(currentPage);
    }, [reloadData, currentPage]);


    //search
    const handleSearch = (e) => {
        e.preventDefault();
        const queryParams = [];

        if (searchQueryName) {
            queryParams.push(`SubCateoryName=${searchQueryName}`);
        }


        if (mainCategory) {
            queryParams.push(`mainCategoryName=${mainCategory}`);
        }


        const queryString = queryParams.join('&');
        const apiUrl = `${GET_CAT_API_BASE_URL}/searchsubcategory?${queryString}`;

        fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setSubCategories(data);
            })
            .catch((error) => {
                console.error('Error:', error);
                toast.error('Data not found.');
            });
    };


    //delete
    const handleDelete = (id) => {

        fetch(`${GET_CAT_API_BASE_URL}/deletesubcategory/${id}`, {
            method: 'DELETE',
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                if (data.status === 'success') {
                    toast.success(data.message || 'Subcategory deleted successfully');

                    const updatedProduct = subCategories.filter((item) => item._id !== data.result._id);
                    setSubCategories(updatedProduct);


                    console.log("subCategories", subCategories)
                } else {
                    console.error(`Failed to delete subcategory with ID ${id}`);
                    toast.error(data.message || 'An error occurred while deleting the subcategory');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                toast.error('An error occurred while processing your request');
            });
    }




    const clearSearch = () => {
        // setSearchQueryId('');
        setMaincategory('')
        setSearchQueryName('');
        fetchSubCategories();
    };

    return (
        <div className="flex flex-col main-container px-4 text-sm overflow-hidden">
            <div className="">
                <div className="">
                    <div className="flex justify-between items-center border-b mt-[4rem] md:mt-[1rem] sm:mt-[1rem]">
                        <h1 className={`text-xl px-4 mb-4 category-title ${isDarkMode ? 'dark-mode ' : 'dashbg'} font-semibold`}>
                            Sub category
                        </h1>
                        <Link>
                            <button
                                className=" bg-blue-500 text-white font-semibold px-6 py-2 rounded-lg mb-6 hover:bg-blue-600 focus:outline-none"
                                onClick={subtoggleModal}
                            >
                                Add
                            </button>
                        </Link>
                    </div>


                    <div className="max-w-full mx-auto ">
                        <div className="flex flex-col mt-[-34px]">
                            <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 my-10">
                                <div className="align-middle inline-block w-full rounded-xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] ">
                                    <form className="grid grid-cols-7 gap-2 p-8" onSubmit={handleSearch}>

                                        <input
                                            type="text"
                                            placeholder="Search by Category"
                                            value={mainCategory}
                                            onChange={(e) => setMaincategory(e.target.value)}
                                            className={`px-2 py-1 border rounded ${isDarkMode ? 'dark' : 'text-black'}`}
                                        />


                                        <input
                                            type="text"
                                            placeholder="Search by Sub category Name"
                                            value={searchQueryName}
                                            onChange={(e) => setSearchQueryName(e.target.value)}
                                            className={`px-2 py-1 border rounded ${isDarkMode ? 'dark' : 'text-black'}`}
                                        />
                                        <button
                                            className="cursor-pointer bg-blue-500 text-white font-semibold px-2 py-2 rounded-lg hover:bg-blue-600 focus:outline-none"
                                            type='submit'
                                        >
                                            Search
                                        </button>

                                        <button
                                            className="cursor-pointer bg-red-600 text-white font-semibold px-3 py-2 rounded-lg hover:bg-red-700 focus:outline-none"
                                            onClick={clearSearch}
                                        >
                                            Clear Search
                                        </button>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>



                    <nav className=" flex items-center flex-column flex-wrap md:flex-row justify-between -mt-10 py-3" aria-label="Table navigation">
                        <span className="text-sm font-normal mb-4 md:mb-0 block w-full md:inline md:w-auto text-black text-center">
                            Showing rows
                            <span className="font-semibold font-semibold text-[#7B68EE] rounded-full bg-gray-300 px-1  ml-2 mr-1">
                                {(currentPage - 1) * 5 + 1}
                            </span>
                            to
                            <span className="font-semibold font-semibold text-[#7B68EE] rounded-full bg-gray-300 px-1  ml-1">
                                {(currentPage - 1) * 5 + 5}
                            </span>
                        </span>


                        <div className="py-4 pr-4 w-auto justify-content float-right">
                            <ReactPaginate
                                previousLabel={"←"}
                                nextLabel={" →"}
                                pageCount={totalPages}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active "}
                            />
                        </div>
                    </nav>

                    <div className="relative overflow-auto shadow-md">
                        <div className="table-container overflow-auto max-h-[320px]">
                            <table className="border min-w-full divide-y divide-gray-200 w-full text-sm text-left rtl:text-right text-gray-500 sticky top-0 bg-white bg-white">
                                <thead className="sticky top-[-2px] text-center text-xs text-white bg-[#7B68EE]">
                                    <tr className='text-center'>
                                        <th scope="col" className="px-6 py-3 text-center flex">
                                            No
                                        </th>
                                        <th scope="col" className="px-6 py-3">

                                            Category Id
                                            <button
                                                className="ml-2 text-white hover:text-white"
                                                onClick={() => idWise(idSortDirection)}
                                            >
                                                {idSortDirection === 'asc' ? <FaCaretUp /> : <FaCaretDown />}
                                            </button>

                                        </th>
                                        {/* <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center">
                                        Sub Category Id

                                    </div>
                                </th> */}
                                        <th scope="col" className="px-6 py-3">

                                            Category
                                            <button
                                                className="ml-2 text-white hover:text-white"
                                                onClick={() => categoryNameWise(categoryNameSortDirection)}
                                            >
                                                {categoryNameSortDirection === 'asc' ? <FaCaretUp /> : <FaCaretDown />}
                                            </button>

                                        </th>
                                        <th scope="col" className="px-6 py-3">

                                            Subcategory Name
                                            <button
                                                className="ml-2 text-white hover:text-white"
                                                onClick={() => categoryNameWise(categoryNameSortDirection)}
                                            >
                                                {categoryNameSortDirection === 'asc' ? <FaCaretUp /> : <FaCaretDown />}
                                            </button>

                                        </th>
                                        <th scope="col" className="px-6 py-3">

                                            Subcategory Image


                                        </th>
                                        <th scope="col" className="px-6 py-3">

                                            Size Image


                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-center bg-white border-b bg-white hover:border-gray-200 hover:bg-gray-50 hover:bg-white '>
                                    {subCategories.length === 0 ? (
                                        <tr>
                                            <td colSpan="4" className="text-center py-4">
                                                No categories found.
                                            </td>
                                        </tr>
                                    ) : (

                                        subCategories.map((el, i) => {

                                            return (
                                                <tr className='text-center bg-white border-b bg-white border-gray-200 hover:bg-gray-50 hover:bg-white' key={i + 1}>
                                                    <td className="px-6 py-4 w-1/12 ">
                                                        {(currentPage - 1) * 5 + i + 1}
                                                    </td>

                                                    <td className="px-4 py-4 ">
                                                        <div className="leading-5 text-gray-900">
                                                            {el.id}
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-4 ">
                                                        <div className="leading-5 text-gray-900">
                                                            {el.mainCategoryName}
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-4 "> <div className="leading-5 text-gray-900">{el.SubCateoryName}</div></td>
                                                    <td className="px-4 py-4 ">
                                                        <div className="flex items-center justify-center">
                                                            <div className="leading-5 text-gray-900">
                                                                <img src={`https://ecommmerceapi.hindustanrides.in/uploads/${el.subCatImg}`} className='w-10 h-10' />
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td className="px-4 py-4">
                                                        <div className="flex items-center justify-center">
                                                            <div className="leading-5 text-gray-900">
                                                                {el.sizeImg ? (
                                                                    <img src={`https://ecommmerceapi.hindustanrides.in/uploads/${el.sizeImg}`} className='w-10 h-10' alt="Size Image" />
                                                                ) : (
                                                                    <p className='text-red-700 text-bold'>No Size Image Select.</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>



                                                    <td className="px-4 py-4 bg-white-200 text-center ">
                                                        <div className="leading-5 text-gray-900 flex space-x-6 justify-center items-center">
                                                            <FaEdit size={20} fill='green' onClick={() => handleEdit(el.id)} className='cursor-pointer' />
                                                            <FaTrash size={20} fill='red' onClick={() => handleDelete(el._id)} className='cursor-pointer' />
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>

                            {/* <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                            <div className="flex flex-1 justify-between sm:hidden">
                                <a
                                    href="#"
                                    className="relative inline-flex rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ml-auto"
                                    onClick={() => {
                                        if (currentPage > 1) {
                                            fetchSubCategories(currentPage - 1);
                                        }
                                    }}
                                >
                                    Previous
                                </a>
                                <a
                                    href="#"
                                    className="relative ml-3 inline-flex rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                    onClick={() => {
                                        if (currentPage < totalPages) {
                                            fetchSubCategories(currentPage + 1);
                                        }
                                    }}
                                >
                                    Next
                                </a>
                            </div>
                            <div className="hidden sm:flex sm:flex-1 sm:sm:justify-between">
                                <div className="ml-auto">
                                    <nav
                                        className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                                        aria-label="Pagination"
                                    >
                                        {Array.from({ length: totalPages }, (_, index) => (
                                            <button
                                                key={index}
                                                onClick={() => fetchSubCategories(index + 1)}
                                                className={`${currentPage === index + 1
                                                    ? 'bg-blue-500 text-white'
                                                    : 'text-gray-400'
                                                    } relative inline-flex items-center rounded-md px-4 py-2 text-sm font-medium focus:z-20 focus:outline-offset-0`}
                                            >
                                                {index + 1}
                                            </button>
                                        ))}
                                    </nav>
                                </div>
                            </div>
                        </div> */}
                        </div>
                    </div>

                </div>
                {subShowModal && (
                    <SubCategoryModal
                        isOpen={subShowModal}
                        subtoggleModal={subtoggleModal}
                        setSubCategories={setSubCategories}
                        subCategories={subCategories}
                        setCategories={setCategories}
                        isEditMode={isEditMode}
                        categoryToEdit={categoryToEdit}
                        categories={categories}
                        setCategoryToEdit={setCategoryToEdit}
                        editItemId={editItemId}
                        setEditItemId={setEditItemId}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        subcategoryName={subcategoryName}
                        setSubcategoryName={setSubcategoryName}
                        setSubCatEditImg={setSubCatEditImg}
                        subCatEditImg={subCatEditImg}
                        sizeImg={sizeImg}
                        setSizeImg={setSizeImg}
                        onReloadData={handleReloadData}
                    />
                )}
            </div>
        </div>
    )
}

export default SubCategoryGet