import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../images/hiya (1).png';
import { FaEye,FaEyeSlash } from "react-icons/fa";

const initialForm = {
  username: '',
  password: '',
};

const Login = ({ setIsLoggedIn }) => {
  const [loginData, setLoginData] = useState(initialForm);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!loginData.username.trim()) {
      newErrors.username = 'Username is required';
      isValid = false;
    }

    if (!loginData.password.trim()) {
      newErrors.password = 'Password is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleLogin = async () => {
    try {
      if (!validateForm()) {
        return;
      }

      const response = await fetch('https://ecommmerceapi.hindustanrides.in/api/v1/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });

      const jsonResponse = await response.json();
      console.log(jsonResponse); 

      if (jsonResponse.status === 'success') {
        localStorage.setItem('token', jsonResponse.token);
        setIsLoggedIn(true);
        navigate('/dashboard');
        toast.success(jsonResponse.message);
      } else if (jsonResponse.status === 'fail') {
        console.log(jsonResponse.status === 'fail')
        toast.error(jsonResponse.message);
      }
    } catch (err) {
      console.error('An error occurred:', err);
      toast.error('An unexpected error occurred. Please try again later.');
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className='min-h-screen bg-gray-100 text-gray-900 flex justify-center'>
        <div className='max-w-screen-xl m-0 sm:m-20 bg-white shadow sm:rounded-lg flex justify-center flex-1'>
          <div className='lg:w-1/2 xl:w-5/12 p-6 sm:p-12'>
            <div>
              <img src={logo} className='w-[11rem] mx-auto py-7' alt='Logo' />
            </div>
            <div className='mt-12 flex flex-col items-center'>
              <h1 className='text-2xl xl:text-3xl font-extrabold'>
                Login for Online Shop
              </h1>
              <div className='w-full flex-1 mt-8'>
                <div className='mx-auto max-w-xs'>
                  <input
                    className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                                        focus:border-gray-400 focus:bg-white ${errors.username ? 'border-red-500' : ''
                    }`}
                    type='email'
                    placeholder='Username'
                    name='username'
                    onChange={handleChange}
                  />
                  {errors.username && (
                    <p className='text-red-500 text-sm mt-1'>
                      {errors.username}
                    </p>
                  )}

                  {/* <input
                    className={`w-full px-8 py-4  mt-6 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                                        focus:border-gray-400 focus:bg-white ${errors.password ? 'border-red-500' : ''
                    }`}
                    type='password'
                    placeholder='Password'
                    name='password'
                    onChange={handleChange}
                  />
                  {errors.password && (
                    <p className='text-red-500 text-sm mt-1'>
                      {errors.password}
                    </p>
                  )} */}
<div className='mx-auto max-w-xs relative'>
  <input
    className={`w-full px-8 py-4 mt-6 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                focus:border-gray-400 focus:bg-white ${errors.password ? 'border-red-500' : ''
    }`}
    type={showPassword ? 'text' : 'password'}
    placeholder='Password'
    name='password'
    onChange={handleChange}
  />
  <div className='absolute right-4 top-14 transform -translate-y-1/2'>
    <button
      type='button'
      className='focus:outline-none'
      onClick={handleTogglePassword}
    >
      {showPassword ? (
        <span className='text-gray-500 text-xl'><FaEye/></span> // Eye icon
      ) : (
        <span className='text-gray-500 text-xl'><FaEyeSlash/></span> // Eye-slash icon
      )}
    </button>
  </div>
</div>

                  <button
                    className='mt-5 tracking-wide font-semibold bg-indigo-500 
                                        text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all 
                                        duration-300 ease-in-out flex items-center justify-center focus:shadow-outline 
                                        focus:outline-none'
                    type='button'
                    onClick={handleLogin}
                  >
                    <i className='fas fa-user-plus mr-2'></i>
                    <span className='ml-3'>Login</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='flex-1 bg-indigo-100 text-center hidden lg:flex'>
            <div
              className='m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat'
              style={{
                backgroundImage: `url('https://storage.googleapis.com/devitary-image-host.appspot.com/15848031292911696601-undraw_designer_life_w96d.svg')`,
              }}
            ></div>
          </div>
        </div>
        <div
          className='REMOVE-THIS-ELEMENT-IF-YOU-ARE-USING-THIS-PAGE hidden treact-popup fixed inset-0 flex items-center justify-center'
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          }}
        ></div>
      </div>
    </>
  );
};

export default Login;
