import React, { useEffect, useState } from 'react'
import { FaTrash } from 'react-icons/fa';
import { IoEyeSharp } from 'react-icons/io5';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const GET_CAT_API_BASE_URL = 'https://ecommmerceapi.hindustanrides.in/api/v1';

const ShippimhGet = () => {
    const [shipingData, setShippingData] = useState([])


    //get
    const fetchCategories = () => {
        fetch(`${GET_CAT_API_BASE_URL}/getshipping`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setShippingData(data.result);
               

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    //delete 
    const deleteShippingAddress = (id) => {
        fetch(`${GET_CAT_API_BASE_URL}/deleteshipping/${id}`, {
            method: 'DELETE',
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            if (data.status === 'success') {
                console.log(data.message || 'Shipping address deleted successfully');
                
          const updatedProduct = shipingData.filter((item) => item._id !== data.result._id);
          setShippingData(updatedProduct);

               
            } else {
                console.error(`Failed to delete shipping address with ID ${id}`);
                console.error(data.message || 'An error occurred while deleting the shipping address');
               
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            
        });
    };
    

    return (
        <div className="flex flex-col main-container px-4 overflow-hidden text-sm ">
            <div className="flex justify-between items-center border-b mt-[4rem] md:mt-[1rem] sm:mt-[1rem]">
                <h1 className="text-xl px-4 mb-4 category-title text-balck-400 font-semibold" style={{ color: "black" }}>
                    UserDetail
                </h1>
            </div>
            <div className="relative overflow-auto shadow-md mt-5">
                <div className="table-container overflow-auto max-h-[400px]">
                    <table className="border min-w-full divide-y divide-gray-200 w-full text-sm text-left rtl:text-right text-gray-500 sticky top-0 bg-white ">
                        <thead className="sticky top-[-2px] text-center text-xs text-white bg-[#7B68EE]">
                            <tr className='text-center'>
                                <th scope="col" className="px-6 py-3 text-center ">
                                    No

                                </th>
                                <th scope="col" className="px-6 py-3 text-center">
                                    Name
                                </th>
                                <th scope="col" className="px-6 py-3 text-center">
                                    City
                                </th>
                                <th scope="col" className="px-6 py-3 text-center">

                                    phoneno


                                </th>
                                <th scope="col" className="px-6 py-3 text-center">

                                    email


                                </th>


                                <th scope="col" className="px-6 py-3 text-center">

                                    streetAddress


                                </th>
                                <th scope="col" className="px-6 py-3 text-center">

                                    state


                                </th>



                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-center bg-white border-b bg-white hover:border-gray-200 hover:bg-gray-50 hover:bg-white '>
                            {shipingData.length === 0 ? (
                                <tr>
                                    <td colSpan="4" className="text-center py-4">
                                        No categories found.
                                    </td>
                                </tr>
                            ) : (
                                shipingData.map((el, i) => {
                                    console.log(el)
                                    return (
                                        <tr className='text-center bg-white border-b bg-white border-gray-200 hover:bg-gray-50 hover:bg-white' key={i + 1}>
                                            <td className="px-6 py-4">
                                                {i + 1}
                                            </td>

                                            <td className="px-6 py-4">
                                                <div className="leading-5 text-gray-900">
                                                    {el.Name}
                                                </div>
                                            </td>

                                            <td className="px-6 py-4 ">
                                                <div className="leading-5 text-gray-900">
                                                    {el.city}
                                                </div>
                                            </td>

                                            <td className="px-6 py-4 ">
                                                <div className="leading-5 text-gray-900">
                                                    {el.phoneno}
                                                </div>
                                            </td>

                                            <td className="px-6 py-4 ">
                                                <div className="leading-5 text-gray-900">
                                                    {el.email}
                                                </div>
                                            </td>


                                            <td className="px-6 py-4 ">
                                                <div className="leading-5 text-gray-900">
                                                    {el.streetAddress}
                                                </div>
                                            </td>

                                            <td className="px-6 py-4 ">
                                                <div className="leading-5 text-gray-900">
                                                    {el.state}
                                                </div>
                                            </td>

                                        
                                            <td className="px-4 py-4 bg-white-200 text-center ">
                                                <div className="leading-5 text-gray-900 flex space-x-6 justify-center items-center">
                                                <IoEyeSharp size={20} fill='#3B82F9'  className='cursor-pointer' />
                                                <FaTrash size={20} fill='red' onClick={() => deleteShippingAddress(el._id)} className='cursor-pointer'/>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                    </table>


                </div>
            </div>

        </div>
    )
}

export default ShippimhGet