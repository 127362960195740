import React, { useEffect, useState } from 'react'
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const GET_CAT_API_BASE_URL = 'http://localhost:4800/api/v1';

const Order = () => {
    const [getOrder, setOrder] = useState([])

    //get

    const fetchCategories = () => {
        fetch(`${GET_CAT_API_BASE_URL}/getshipping`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setOrder(data.result);
                console.log('data.result =>', data.result);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        fetchCategories();
    }, []);


    return (
        <div className="flex flex-col main-container px-4 overflow-hidden text-sm ">
            <div className="flex justify-between items-center border-b mt-[4rem] md:mt-[1rem] sm:mt-[1rem]">
                <h1 className="text-xl px-4 mb-4 category-title text-balck-400 font-semibold" style={{ color: "black" }}>
                    Order Detail
                </h1>
            </div>
            <div className="relative overflow-auto shadow-md mt-5">
                <div className="table-container overflow-auto max-h-[400px]">
                    <table className="border min-w-full divide-y divide-gray-200 w-full text-sm text-left rtl:text-right text-gray-500 sticky top-0 bg-white ">
                        <thead className="sticky top-[-2px] text-center text-xs text-white bg-[#7B68EE]">
                            <tr className='text-center'>
                                <th scope="col" className="px-6 py-3 text-center ">
                                    No

                                </th>
                                <th scope="col" className="px-6 py-3 text-center ">
                                Id

                                </th>
                                {/* <th scope="col" className="px-6 py-3 text-center ">
                                    Category

                                </th> */}
                                <th scope="col" className="px-6 py-3 text-center ">
                                    Subcategory

                                </th>
                                <th scope="col" className="px-6 py-3 text-center">
                                    Image
                                </th>
                                <th scope="col" className="px-6 py-3 text-center">
                                    Name
                                </th>
                                <th scope="col" className="px-6 py-3 text-center">
                                    Price
                                </th>
                                <th scope="col" className="px-6 py-3 text-center">
                                    Size
                                </th>

                                <th scope="col" className="px-6 py-3 text-center">
                                    Qty
                                </th>
                                <th scope="col" className="px-6 py-3 text-center">
                                Total
                                </th>



                              
                            </tr>
                        </thead>
                        <tbody className='text-center bg-white border-b bg-white hover:border-gray-200 hover:bg-gray-50 hover:bg-white '>
                            {getOrder.length === 0 ? (
                                <tr>
                                    <td colSpan="9" className="text-center py-4">
                                        No orders found.
                                    </td>
                                </tr>
                            ) : (
                                getOrder.map((order, index) => {
                                    const productData = order.productData; // Assuming productData is an array

                                    return productData.map((data, dataIndex) => (
                                        <tr className='text-center bg-white border-b bg-white border-gray-200 hover:bg-gray-50 hover:bg-white' key={`${order.id}_${dataIndex}`}>
                                            <td className="px-6 py-4">{index + 1}</td>
                                            <td className="px-6 py-4">{data.productId}</td>
                                            {/* <td className="px-6 py-4">{data.productName}</td> */}
                                            <td className="px-6 py-4">{data.subcategoryName}</td>
                                            <td className="px-6 py-4">
                                                <img src={`https://ecommmerceapi.hindustanrides.in/uploads/${data.pImg?.[0]}`} className='w-10 h-10 ' />
                                            </td>
                                            <td className="px-6 py-4">{data.productName}</td>
                                            <td className="px-6 py-4">{data.price}</td>
                                            <td className="px-6 py-4">
                                            {data.selectedSizesArray && Array.isArray(data.selectedSizesArray) && data.selectedSizesArray.length > 0 && data.selectedSizesArray[0] && data.selectedSizesArray[0].length > 0 ? (
                                                    <p className="" style={{ fontWeight: "bold" }}>{data.selectedSizesArray}</p>
                                                ) : data.selectedSize && data.selectedSize.length > 0 ? (
                                                    <p className="" >{data.selectedSize}</p>
                                                ) : (
                                                    <h3 className="">-</h3>
                                                )}

                                            </td>
                                            <td className="px-6 py-4">{data.quantity}</td>
                                             <td className="px-6 py-4">Rs. {data.price * data.quantity}</td>
                                          
                                            <td className="px-4 py-4 bg-white-200 text-center">
                                                <div className="leading-5 text-gray-900 flex space-x-6 justify-center items-center">
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                    ));
                                })
                            )}
                        </tbody>

                    </table>


                </div>
            </div>

        </div>
    )
}

export default Order