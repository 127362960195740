import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
const GET_CAT_API_BASE_URL = 'https://ecommmerceapi.hindustanrides.in/api/v1';


const initialForm = {
  pName: '',
  price: '',
  details: '',
  discountPrice: '',
  pImg: [],
  subcategoryId: '',
  subcategoryName: '',
  MaincategoryName: '',
  size: [],
  rating: '',
  bottomSize: [],
  shoesSize: [],
  stock:''
};

const ProductModal = ({ setSelectedCatOption, selectedCatOption, productData, editMode, proShowModal, setProShowModal, isDarkMode, shoproShowModal,
  selectedOption, setSelectedOption, proToggleModal, updateProductData, editedProduct }) => {

  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [formData, setFormData] = useState(initialForm);
  const [selectedSubcategoryName, setSelectedSubcategoryName] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectBottomSize, setSelectBottomSize] = useState([]);
  const [selectShoesSize, setSelectShoesSize] = useState([]);

  const availableSizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'];
  const availableBottomsizes = ['30', '32', '34', '36', '38', '40', '42', '44', '46', '48'];
  const availableShoesSize = ['UK-7', 'UK-8', 'UK-9', 'UK-10', 'UK-11', 'UK-12']

  const fetchAllCategories = async () => {
    try {
      const response = await fetch(`${GET_CAT_API_BASE_URL}/getallcategory`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCategory(data.category);
      setProduct(data.subCategory);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  //HandelSize
  const handleSizeChange = (e, size) => {
    const isChecked = e.target.checked;

    setSelectedSizes((prevSizes) => {
      if (isChecked) {
        return [...prevSizes, size];
      } else {
        return prevSizes.filter((selectedSize) => selectedSize !== size);
      }
    });
  };


  //HandelBottomSize
  const handleBottomSizeChange = (e, bottomSize) => {
    const isChecked = e.target.checked;

    setSelectBottomSize((prevSizes) => {
      if (isChecked) {
        return [...prevSizes, bottomSize];
      } else {
        return prevSizes.filter((selectBottomSize) => selectBottomSize !== bottomSize);
      }
    });
  };


  //HandeShoeslSize
  const handleShoesSizeChange = (e, size) => {
    const isChecked = e.target.checked;

    setSelectShoesSize((prevSizes) => {
      if (isChecked) {
        return [...prevSizes, size];
      } else {
        return prevSizes.filter((selectShoesSize) => selectShoesSize !== size);
      }
    });
  };


  useEffect(() => {
    fetchAllCategories();
  }, []);

  const handleCategoryChange = (e) => {
    setSelectedCategoryId(e.target.value);
    const selectedCategory = product.find((category) => category._id === e.target.value);
    if (selectedCategory) {
      setSelectedSubcategoryName(selectedCategory.subcategoryName);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    setFormData({
      ...formData,
      pImg: files,
    });

  };


  const validateForm = () => {
    const errors = {};

    if (!formData.pName) {
      errors.pName = 'Product Name is required.';
    }

    if (!formData.price || isNaN(formData.price)) {
      errors.price = 'Valid Product Price is required.';
    }

    if (!formData.details) {
      errors.details = 'Product Details are required.';
    }
    if (!formData.stock) {
      errors.stock = 'Product stock are required.';
    }

    if (!selectedOption) {
      errors.subcategoryName = 'Please select a subcategory.';
    }

    if (!selectedCatOption) {
      errors.MaincategoryName = 'Please select a category.';
    }

    if (formData.pImg.length === 0 || formData.pImg.length > 5) {
      errors.pImg = 'Please upload between 1 and 5 images.';
    }

    if (!formData.discountPrice || isNaN(formData.discountPrice)) {
      errors.discountPrice = 'Valid Discount Price is required.';
    }
    if (!formData.rating || isNaN(formData.rating) || formData.rating < 1 || formData.rating > 5 || formData.rating === 0 || formData.rating === -1) {
      errors.rating = 'Rating is required and should be between 1 and 5 (excluding 0 and -1).';
    }

    // if (!selectedSizes || selectedSizes.length === 0) {
    //   errors.size = 'Please select a size.';
    // }

    return errors;
  };

  const handleAddProduct = async () => {
    setFormErrors({});

    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }


    try {
      const subCatId = selectedCategoryId;
      const formDataToSend = prepareFormData();

      const response = await axios.post(`${GET_CAT_API_BASE_URL}/addProduct/${subCatId}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        const newData = response.data.result;
        console.log("newData", newData)
        updateProductData((prevData) => [newData, ...prevData]);
        toast.success('Product added successfully.');
        setProShowModal(false);
        setSelectedOption(null);
        setSelectedCatOption(null);
        setFormData(initialForm)
      } else {
        toast.error('Error adding product. Please try again.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error handling the product addition. Please try again.');
    }
  };

  const handleEditProduct = async () => {

    setFormErrors({});

    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }


    try {
      const formDataToSend = prepareFormData();

      const response = await axios.patch(`${GET_CAT_API_BASE_URL}/updateproduct/${editedProduct._id}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        const updatedData = response.data;
        updateProductData((prevData) => {
          const updatedIndex = prevData.findIndex((product) => product._id === updatedData._id);
          if (updatedIndex !== -1) {
            prevData[updatedIndex] = updatedData;
          }
          return prevData;
        });
        setProShowModal(false);
        toast.success('Product updated successfully!');
      } else {
        toast.error('Error updating product. Please try again.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error handling the product update. Please try again.');
    }
  };

  const prepareFormData = () => {
    const formDataToSend = new FormData();

    formDataToSend.append('pName', formData.pName);
    formDataToSend.append('price', formData.price);
    formDataToSend.append('details', formData.details);
    formDataToSend.append('discountPrice', formData.discountPrice);
    formDataToSend.append('subcategoryName', selectedOption);
    formDataToSend.append('MaincategoryName', selectedCatOption);
    formDataToSend.append('size', selectedSizes);
    formDataToSend.append('bottomSize', selectBottomSize);
    formDataToSend.append('shoesSize', selectShoesSize);
    formDataToSend.append('rating', formData.rating);
    formDataToSend.append('stock', formData.stock);

    for (let i = 0; i < formData.pImg.length; i++) {
      formDataToSend.append('pImg', formData.pImg[i]);
    }
console.log('formDataToSend =>',formDataToSend);


    return formDataToSend;
  };


  useEffect(() => {
    if (editMode && editedProduct) {
      setFormData({
        pName: editedProduct.pName || '',
        price: editedProduct.price || '',
        details: editedProduct.details || '',
        discountPrice: editedProduct.discountPrice || '',
        pImg: editedProduct.pImg,
        subcategoryId: editedProduct.subcategoryId || '',
        subcategoryName: editedProduct.subcategoryName || '',
        MaincategoryName: editedProduct.MaincategoryName || '',
        rating: editedProduct.rating || '',
        stock:editedProduct.stock || ''
      });

      // size
      const sizesArray = Array.isArray(editedProduct.size)
        ? editedProduct.size
        : (typeof editedProduct.size === 'string' ? [editedProduct.size] : '');

      setSelectedSizes(sizesArray);
      // bottom size
      const bottomSizesArray = Array.isArray(editedProduct.bottomSize)
        ? editedProduct.bottomSize
        : (typeof editedProduct.bottomSize === 'string' ? [editedProduct.bottomSize] : '');

      setSelectBottomSize(bottomSizesArray);

      // shoes size
      const shoessizeArray = Array.isArray(editedProduct.shoesSize)
        ? editedProduct.shoesSize
        : (typeof editedProduct.shoesSize === 'string' ? [editedProduct.shoesSize] : '');

      setSelectShoesSize(shoessizeArray);
    }
  }, [editedProduct]);

  return (
    <>
      {proShowModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className=" relative w-full sm:w-[100%] md:w-[80%] lg:w-[60%] my-6 mx-auto">
              <div className="w-[120%] border-0 rounded-lg shadow-lg relative flex flex-col mt-3 bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className={`text-3xl font-semibold ${isDarkMode ? 'dark' : 'text-black'}`}>
                    {editMode ? 'Edit Product Form' : 'Add Product Form'}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={proToggleModal}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto ">
                  <form className="p-4">
                    <div className='grid grid-cols-3 gap-4'>
                      <div className="mb-4 rounded col-span-2 md:col-span-1">
                        <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                          Category:
                        </label>
                        <select
                          className={`w-full border border-gray-300 rounded-lg px-4 py-2.5 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'
                            }`}
                          value={selectedCatOption}
                          onChange={(e) => {
                            setSelectedCatOption(e.target.value);
                            const categorydata = category.find((cat) => cat.MaincategoryName === e.target.value);
                            setSelectedCategoryId(categorydata ? categorydata.id : '');
                          }}
                        >
                          <option >Select an option</option>
                          {category.map((el) => (
                            <option key={el.id} value={el.MaincategoryName}>
                              {el.MaincategoryName}
                            </option>
                          ))}
                        </select>
                        {formErrors.MaincategoryName && <p className="text-red-500">{formErrors.MaincategoryName}</p>}
                      </div>

                      <div className="mb-4 rounded col-span-2 md:col-span-1">
                        <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                          Sub Category:
                        </label>
                        <select
                          className={`w-full border border-gray-300 rounded-lg px-4 py-2.5 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'
                            }`}
                          value={selectedOption}
                          onChange={(e) => {
                            setSelectedOption(e.target.value);
                            const category = product.find((cat) => cat.SubCateoryName === e.target.value);
                            setSelectedCategoryId(category ? category.id : '');
                          }}
                        >
                          <option value="">Select an option</option>
                          {product.map((category) => (
                            <option key={category.id} value={category.SubCateoryName}>
                              {category.SubCateoryName}
                            </option>
                          ))}
                        </select>
                        {formErrors.subcategoryName && <p className="text-red-500">{formErrors.subcategoryName}</p>}
                      </div>


                      <div className="mb-4 rounded col-span-2 md:col-span-1">
                        <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                          Product Name:
                        </label>
                        <input
                          type="text"
                          id="categoryName"
                          name="pName"
                          className={`w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'
                            }`}
                          placeholder="Enter product name"
                          onChange={handleInputChange}
                          value={formData.pName}
                        />
                        {formErrors.pName && <p className="text-red-500">{formErrors.pName}</p>}
                      </div>
                      <div className="mb-4 rounded col-span-2 md:col-span-1">
                        <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                          Product Image:
                        </label>
                        <input
                          type="file"
                          id="categoryName"
                          name="pImg"
                          className={`w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'
                            }`}
                          placeholder="Enter product image"
                          onChange={handleImageChange}
                          multiple
                          accept="image/*"
                        />
                        {formErrors.pImg && <p className="text-red-500">{formErrors.pImg}</p>}
                      </div>
                      <div className="mb-4 rounded col-span-2 md:col-span-1">
                        <label htmlFor="selectedImages" className="block text-gray-600 font-semibold mb-2 ">
                          Selected Images:
                        </label>
                        <div
                          className={`flex ${formData.pImg.length > 0
                            ? 'border-2 border border-gray-300 rounded py-2'
                            : ''
                            }`}
                        >
                          {formData.pImg.length > 0 ? (
                            Array.from(formData.pImg).map((file, index) => (
                              <div key={index} className="image-container">
                                {file instanceof File ? (
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`Selected Image ${index}`}
                                    className="rounded w-32 object-contain px-1"
                                  />
                                ) : (
                                  <img
                                    src={`https://ecommmerceapi.hindustanrides.in/uploads/${file}`}
                                    alt={`Selected Image ${index}`}
                                    className="rounded w-36 h-20 object-contain px-1"
                                  />
                                )}
                              </div>
                            ))
                          ) : (
                            <div className={` border border-gray-300 rounded-lg px-4 py-2.5 w-full ${isDarkMode ? 'dark' : 'text-black'}`}>No images selected</div>
                          )}
                        </div>
                      </div>
                      <div className="mb-4 rounded col-span-2 md:col-span-1">
                        <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                          Product Price:
                        </label>
                        <input
                          type="number"
                          id="categoryName"
                          name="price"
                          className={`w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'
                            }`}
                          placeholder="Enter product price"
                          onChange={handleInputChange}
                          value={formData.price}
                        />
                        {formErrors.price && <p className="text-red-500">{formErrors.price}</p>}
                      </div>
                      <div className="mb-4 rounded ">
                        <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                          Product Details:
                        </label>
                        <textarea
                          type="text"
                          id="categoryName"
                          name="details"
                          rows="1"
                          cols="2"
                          className={`w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500
                         ${isDarkMode ? 'dark' : 'text-black'
                            }`}
                          placeholder="Enter product details"
                          onChange={handleInputChange}
                          value={formData.details}
                        />
                        {formErrors.details && <p className="text-red-500">{formErrors.details}</p>}
                      </div>

                      <div className="mb-4 rounded col-span-2 md:col-span-1">
                        <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                          Discount Price:
                        </label>
                        <input
                          type="number"
                          id="categoryName"
                          name="discountPrice"
                          className={`w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'
                            }`}
                          placeholder="Enter Discount Price"
                          onChange={handleInputChange}
                          value={formData.discountPrice}
                        />
                        {formErrors.discountPrice && <p className="text-red-500">{formErrors.discountPrice}</p>}
                      </div>


                      <div className="mb-4 rounded col-span-2 md:col-span-1">
                        <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                          Rating:
                        </label>
                        <input
                          type="number"
                          id="categoryName"
                          name="rating"
                          className={`w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'
                            }`}
                          placeholder="Enter Rating"
                          onChange={handleInputChange}
                          value={formData.rating}
                        />
                        {formErrors.rating && <p className="text-red-500">{formErrors.rating}</p>}
                      </div>

                      {/* {formErrors.shoesSize && <p className="text-red-500">{formErrors.shoesSize}</p>} */}

                    </div>
                    <div className='grid grid-cols-3 gap-6'>
                      <div className="mb-4 rounded">
                        <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                          Size:
                        </label>
                        <div className='border-dashed border-2 border-[#bcbcbe] py-2 pr-4 pl-2 flex flex-row'>
                          {/* {/ size /} */}
                          <div className="grid grid-cols-5">
                            {availableSizes.map((size) => (
                              <div key={size}>
                                <div className="flex items-center justify-center">
                                  <input
                                    type="checkbox"
                                    id={size}
                                    name="size"
                                    value={size}
                                    onChange={(e) => handleSizeChange(e, size)}
                                    checked={selectedSizes.includes(size)}
                                    className={`largerCheckbox border border-gray-300 rounded-lg px-2 py-2 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'}`}
                                  />
                                  <label htmlFor={size} className="ml-1 text-black">{size}</label>
                                </div>
                              </div>
                            ))}


                          </div>
                          {formErrors.size && <p className="text-red-500">{formErrors.size}</p>}
                        </div>
                      </div>
                      <div className="mb-4 rounded">
                        <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                          Bottom Size:
                        </label>
                        <div className='border-dashed border-2 border-[#bcbcbe] py-2 pr-4 pl-2 flex flex-row'>
                          {/* {/ size /} */}
                          <div className="grid grid-cols-5 ">
                            {availableBottomsizes.map((bottomSize) => (
                              <div key={bottomSize} className="pr-3 mb-2 w-full">
                                <div className="flex items-center justify-center">
                                  <input
                                    type="checkbox"
                                    id={bottomSize}
                                    name="bottomSize"
                                    value={bottomSize}
                                    onChange={(e) => handleBottomSizeChange(e, bottomSize)}
                                    checked={selectBottomSize.includes(bottomSize)}
                                    className={`largerCheckbox border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'}`}
                                  />
                                  <label htmlFor={bottomSize} className="ml-2 text-black">{bottomSize}</label>
                                </div>
                              </div>
                            ))}

                          </div>
                          {formErrors.size && <p className="text-red-500">{formErrors.size}</p>}
                        </div>
                      </div>
                      <div className="">
                        <label htmlFor="categoryName" className="block text-gray-600 font-semibold ">
                          Shoes Size:
                        </label>
                        <div className="grid grid-cols-3 mt-2 border-dashed border-2 border-[#bcbcbe] py-2 pr-4 pl-2">
                          {availableShoesSize.map((shoesSize) => (
                            <div key={shoesSize} className="mx-3">
                              <div className="flex items-center justify-center">
                                <input
                                  type="checkbox"
                                  id={shoesSize}
                                  name="shoesSize"
                                  value={shoesSize}
                                  onChange={(e) => handleShoesSizeChange(e, shoesSize)}
                                  checked={selectShoesSize.includes(shoesSize)}
                                  className={`largerCheckbox border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'}`}
                                />
                              </div>
                              <label htmlFor={shoesSize} className="mt-2 text-center text-black">{shoesSize}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                      {formErrors.shoesSize && <p className="text-red-500">{formErrors.shoesSize}</p>}
                    </div>

                    <div className="mb-4 rounded col-span-2 md:col-span-1">
                        <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                          Product Stock:
                        </label>
                        <input
                          type="text"
                          id="categoryName"
                          name="stock"
                          className={`w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'
                            }`}
                          placeholder="Enter product Stock"
                          onChange={handleInputChange}
                          value={formData.stock}
                        />
                        {formErrors.stock && <p className="text-red-500">{formErrors.stock}</p>}
                      </div>
                  </form>
                </div>

                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={shoproShowModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-green-500 text-white active-bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                    type="submit"
                    onClick={editMode ? handleEditProduct : handleAddProduct}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          {proShowModal && <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>}
        </>
      ) : null}
    </>
  );
};

export default ProductModal;

{/* <div className="mb-4 rounded">
                      <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                        Size:
                      </label>
                      <div className="flex flex-row">
                        {availableSizes.map((size) => (
                          <div key={size} className="flex items-center mr-4">
                            <input
                              type="checkbox"
                              id={size}
                              name="size"
                              value={size}
                              onChange={(e) => handleSizeChange(e, size)}
                              checked={selectedSizes.includes(size)}
                              className={`w-full border mr-2 border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'
                                }`}
                            />
                            <label htmlFor={size} className="text-black">{size}</label>
                          </div>
                        ))}
                        {formErrors.size && <p className="text-red-500">{formErrors.size}</p>}
                      </div>

                    </div> */}

{/* <div className="mb-4 rounded">
                      <label htmlFor="categoryName" className="block text-gray-600 font-semibold mb-2">
                        Size:
                      </label>
                   
                      <div className="flex flex-row">
                        <table>
                          <tbody>
                            <tr className='border-2 '>
                              {availableSizes.map((size) => (
                                <td key={size} className="pr-4 border-2">
                                  <div className="flex items-center justify-center">
                                    <input
                                      type="checkbox"
                                      id={size}
                                      name="size"
                                      value={size}
                                      onChange={(e) => handleSizeChange(e, size)}
                                      checked={selectedSizes.includes(size)}
                                      className={`largerCheckbox border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'
                                        }`}
                                    />
                                    <label htmlFor={size} className="ml-2 text-black">{size}</label>
                                  </div>
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                        {formErrors.size && <p className="text-red-500">{formErrors.size}</p>}
                      </div>

                      <div className="flex flex-row mt-2">
                        <table>
                          <tbody>
                            <tr className='border-2 '>
                              {availableBottomsizes.map((bottomSize) => (
                                <td key={bottomSize} className="pr-4 border-2">
                                  <div className="flex r justify-center">
                                    <input
                                      type="checkbox"
                                      id={bottomSize}
                                      name="bottomSize"
                                      value={bottomSize}
                                      onChange={(e) => handleBottomSizeChange(e, bottomSize)}
                                      checked={selectBottomSize.includes(bottomSize)}
                                      className={`largerCheckbox border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:border-blue-500 ${isDarkMode ? 'dark' : 'text-black'
                                        }`}
                                    />
                                    <label htmlFor={bottomSize} className="ml-2 text-black">{bottomSize}</label>
                                  </div>
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>

                      </div>
                    </div> */}