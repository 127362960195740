// import React, { useEffect, useState } from 'react';
// import { IoMdAdd } from 'react-icons/io';
// import { Link } from 'react-router-dom';
// import CategoryModal from './CategoryModal';
// import { FaTrash, FaEdit, FaCaretUp, FaCaretDown } from 'react-icons/fa';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
// import ReactPaginate from 'react-paginate';
// const GET_CAT_API_BASE_URL = 'https://ecommmerceapi.hindustanrides.in/api/v1';

// const Category = ({ isDarkMode, isSidebarOpen }) => {
//   const [showModal, setShowModal] = useState(false);
//   const [categories, setCategories] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [isEditMode, setIsEditMode] = useState(false);
//   const [categoryToEdit, setCategoryToEdit] = useState(null);
//   const [idSortDirection, setIdSortDirection] = useState('asc');
//   const [categoryNameSortDirection, setCategoryNameSortDirection] = useState('asc');
//   const [searchQueryId, setSearchQueryId] = useState(''); // State for ID search
//   const [searchQueryName, setSearchQueryName] = useState('');
//   const [selectedCategories, setSelectedCategories] = useState([]);
//   const [catImg, setCatImg] = useState([]);

//   const toggleModal = () => {
//     setShowModal(!showModal);
//     setIsEditMode(false);
//     setCategoryToEdit(null);
//   };

//   const editCategory = (categoryId) => {
//     const category = categories.find((c) => c.id === categoryId);
//     console.log("category", category)
//     if (category) {
//       setIsEditMode(true);
//       setCategoryToEdit(category);
//       setCatImg(category.categoryImg)
//       setShowModal(true);
//     }
//   };
//   const handlePageClick = (selectedPage) => {
//     setCurrentPage(selectedPage.selected + 1);
//   };

//   //get
//   const fetchCategories = (page = 1, limit = 5) => {
//     fetch(`${GET_CAT_API_BASE_URL}/getcategory/${page}?limit=${limit}`)
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then((data) => {
//         setCategories(data.result);
//         setCurrentPage(data.page);
//         setTotalPages(data.totalPages);
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   };

//   useEffect(() => {
//     fetchCategories(currentPage);
//   }, [currentPage]);

//   //delete 
//   const deleteCategory = (id) => {
//     console.log(id);
//     fetch(`${GET_CAT_API_BASE_URL}/deleteByCatId/${id}`, {
//       method: 'DELETE',
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then((data) => {
//         if (data.status) {
//           console.log(data.status)
//           toast.success(data.message || 'Category deleted successfully');


//           const updatedProduct = categories.filter((item) => item._id !== data.result._id);
//           setCategories(updatedProduct);



//         } else {
//           console.error(`Failed to delete category with ID ${id}`);
//           toast.error(data.message || 'An error occurred while deleting the category');
//         }
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//         toast.error('An error occurred while processing your request');
//       });
//   };

//   //sort
//   const idWise = (sortBy) => {
//     const sortedCategories = [...categories];
//     const isDescending = sortBy === 'desc';

//     sortedCategories.sort((a, b) => {
//       const idA = parseFloat(a.id.match(/\d+/)[0]);
//       const idB = parseFloat(b.id.match(/\d+/)[0]);

//       if (isDescending) {
//         return idB - idA;
//       } else {
//         return idA - idB;
//       }
//     });

//     setCategories(sortedCategories);
//     setIdSortDirection(isDescending ? 'asc' : 'desc');
//   };

//   const categoryNameWise = (sortBy) => {
//     const sortedCategories = [...categories];
//     const isDescending = sortBy === 'desc';

//     sortedCategories.sort((a, b) => {
//       if (isDescending) {
//         return b.MaincategoryName.localeCompare(a.MaincategoryName);
//       } else {
//         return a.MaincategoryName.localeCompare(b.MaincategoryName);
//       }
//     });

//     setCategories(sortedCategories);
//     setCategoryNameSortDirection(isDescending ? 'asc' : 'desc');
//   };

//   // search

//   const handleSearch = () => {
//     const queryParams = [];


//     if (searchQueryName) {
//       queryParams.push(`MaincategoryName=${searchQueryName}`);
//     }


//     const queryString = queryParams.join('&');
//     const apiUrl = `${GET_CAT_API_BASE_URL}/searchcategory?${queryString}`;

//     fetch(apiUrl)
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then((data) => {
//         setCategories(data);
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//         toast.error('Data not Found.');
//       });
//   };

//   const clearSearch = () => {
//     setSearchQueryId('');
//     setSearchQueryName('');
//     fetchCategories();
//   };


//   // const clearSearch = () => {
//   //   setSearchQueryId('');
//   //   setSearchQueryName('');
//   //   fetchCategories(); // Fetch all categories to reset the list
//   // };


//   const handleSelectCategory = (categoryId) => {
//     console.log(categoryId)
//     if (selectedCategories.includes(categoryId)) {

//       setSelectedCategories((prevSelected) => prevSelected.filter(id => console.log(prevSelected)));
//     } else {
//       setSelectedCategories((prevSelected) => [...prevSelected, categoryId]);
//     }
//   };


//   const handleDeleteSelected = () => {
//     selectedCategories.forEach((categoryId) => {
//       deleteCategory(categoryId);
//     });
//     setSelectedCategories([]);
//   };


//   return (
//     <>

//       <div className="flex flex-col main-container px-4 overflow-hidden text-sm ">
//         <div className="">
//           <div className="">
//             <div className="flex justify-between items-center border-b mt-[4rem] md:mt-[1rem] sm:mt-[1rem]">
//               <h1 className={`text-xl px-4 mb-4 category-title ${isDarkMode ? 'dark-mode ' : 'dashbg'} font-semibold`}>
//                 Category
//               </h1>
//               <Link>
//                 <button
//                   className="bg-blue-500 text-white font-semibold px-6 py-2 rounded-lg mb-6 hover:bg-blue-600 focus:outline-none"
//                   onClick={toggleModal}
//                 >
//                   Add
//                 </button>
//               </Link>
//             </div>

//             <div className="flex flex-col mt-[25px]">
//               <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 py-14">
//                 <div className="align-middle inline-block w-full rounded-xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] ">
//                   <div className="grid grid-cols gap-2 py-3 px-4">


//                     <div className="flex items-center space-x-4 py-3">
//                       <input
//                         type="text"
//                         placeholder="Search by Name"
//                         value={searchQueryName}
//                         onKeyUp={handleSearch} 
//                         onChange={(e) => setSearchQueryName(e.target.value)}
//                         className={`px-2 py-1 border rounded ${isDarkMode ? 'dark' : 'text-black'}`}
//                       />
//                       {/* <button
//                         className="bg-blue-500 text-white font-semibold px-2 py-2 rounded-lg hover:bg-blue-600 focus:outline-none"
//                         onClick={handleSearch}
//                       >
//                         Search
//                       </button> */}

//                       <button
//                         className="bg-red-600 text-white font-semibold px-3 py-2 rounded-lg hover:bg-red-700 focus:outline-none"
//                         onClick={clearSearch}
//                       >
//                         Clear Search
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <nav className=" flex items-center flex-column flex-wrap md:flex-row justify-between  py- mt-10" aria-label="Table navigation">
//               <span className="text-sm font-normal mb-4 md:mb-0 block w-full md:inline md:w-auto text-black text-center">
//                 Showing rows
//                 <span className="font-semibold font-semibold text-[#7B68EE] rounded-full bg-gray-300 px-1  ml-2 mr-1">
//                   {(currentPage - 1) * 5 + 1}
//                 </span>
//                 to
//                 <span className="font-semibold font-semibold text-[#7B68EE] rounded-full bg-gray-300 px-1  ml-1">
//                   {(currentPage - 1) * 5 + 5}
//                 </span>
//               </span>


//               <div className="py-4 pr-4 w-auto justify-content float-right">
//                 <ReactPaginate
//                   previousLabel={"←"}
//                   nextLabel={" →"}
//                   pageCount={totalPages}
//                   onPageChange={handlePageClick}
//                   containerClassName={"pagination"}
//                   previousLinkClassName={"pagination__link"}
//                   nextLinkClassName={"pagination__link"}
//                   disabledClassName={"pagination__link--disabled"}
//                   activeClassName={"pagination__link--active "}
//                 />
//               </div>
//             </nav>


//             <div className="relative overflow-auto shadow-md">
//               <div className="table-container overflow-auto max-h-full">
//                 <table className="border min-w-full divide-y divide-gray-200 w-full text-sm text-left rtl:text-right text-gray-500 sticky top-0 bg-white ">
//                   <thead className="sticky top-[-2px] text-center text-xs text-white bg-[#7B68EE]">
//                     <tr className='text-center'>
//                       <th scope="col" className="px-6 py-3 text-center ">
//                         No

//                       </th>
//                       <th scope="col" className="px-6 py-3 text-center">

//                         Id
//                         <button
//                           className="ml-2 text-white hover:text-white"
//                           onClick={() => idWise(idSortDirection)}
//                         >
//                           {idSortDirection === 'asc' ? <FaCaretUp /> : <FaCaretDown />}
//                         </button>
//                         {/* </span> */}
//                       </th>
//                       <th scope="col" className="px-6 py-3">

//                         Category Name
//                         <button
//                           className="ml-2 text-white hover:text-white"
//                           onClick={() => categoryNameWise(categoryNameSortDirection)}
//                         >
//                           {categoryNameSortDirection === 'asc' ? <FaCaretUp /> : <FaCaretDown />}
//                         </button>

//                       </th>
//                       <th>
//                         <div className="flex items-center ml-4">
//                           Image
//                         </div>
//                       </th>
//                       <th scope="col" className="px-6 py-3">
//                         Action
//                       </th>
//                     </tr>
//                   </thead>
//                   <tbody className='text-center bg-white border-b bg-white hover:border-gray-200 hover:bg-gray-50 hover:bg-white '>
//                     {categories.length === 0 ? (
//                       <tr>
//                         <td colSpan="4" className="text-center py-4">
//                           No categories found.
//                         </td>
//                       </tr>
//                     ) : (
//                       categories.map((el, i) => {
//                         console.log(el)
//                         return (
//                           <tr className='text-center bg-white border-b bg-white border-gray-200 hover:bg-gray-50 hover:bg-white' key={i + 1}>
//                             <td className="px-6 py-4">
//                               {(currentPage - 1) * 5 + i + 1}
//                             </td>

//                             <td className="px-6 py-4">
//                               <div className="leading-5 text-gray-900">
//                                 {el.id}
//                               </div>
//                             </td>

//                             <td className="px-6 py-4 ">
//                               <div className="leading-5 text-gray-900">
//                                 {el.MaincategoryName}
//                               </div>
//                             </td>

//                             <td className="px-6 py-4 ">
//                               <div className="leading-5 text-gray-900">
//                                 <img src={`https://ecommmerceapi.hindustanrides.in/uploads/${el.categoryImg}`} className='w-10 h-10 ' />
//                               </div>
//                             </td>

//                             <td className="px-4 py-4 bg-white-200 text-center ">
//                               <div className="leading-5 text-gray-900 flex space-x-6 justify-center items-center">
//                                 <FaEdit size={20} fill='green' onClick={() => editCategory(el.id)} className='cursor-pointer' />
//                                 <FaTrash size={20} fill='red' onClick={() => deleteCategory(el._id)} className='cursor-pointer' />
//                               </div>
//                             </td>
//                           </tr>
//                         );
//                       })
//                     )}
//                   </tbody>
//                 </table>


//               </div>
//             </div>





//           </div>
//           {showModal && (
//             <CategoryModal
//               isOpen={showModal}
//               toggleModal={toggleModal}
//               categories={categories}
//               setCategories={setCategories}
//               isEditMode={isEditMode}
//               categoryToEdit={categoryToEdit}
//               setCatImg={setCatImg}
//               catImg={catImg}

//             />
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Category;



import React, { useEffect, useState } from 'react';
import { IoMdAdd } from 'react-icons/io';
import { Link } from 'react-router-dom';
import CategoryModal from './CategoryModal';
import { FaTrash, FaEdit, FaCaretUp, FaCaretDown } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
const GET_CAT_API_BASE_URL = 'https://ecommmerceapi.hindustanrides.in/api/v1';

const Category = ({ isDarkMode, isSidebarOpen }) => {
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isEditMode, setIsEditMode] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [idSortDirection, setIdSortDirection] = useState('asc');
  const [categoryNameSortDirection, setCategoryNameSortDirection] = useState('asc');
  const [searchQueryId, setSearchQueryId] = useState(''); // State for ID search
  const [searchQueryName, setSearchQueryName] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [catImg, setCatImg] = useState([]);

  const toggleModal = () => {
    setShowModal(!showModal);
    setIsEditMode(false);
    setCategoryToEdit(null);
  };

  const editCategory = (categoryId) => {
    const category = categories.find((c) => c.id === categoryId);
    console.log("category", category)
    if (category) {
      setIsEditMode(true);
      setCategoryToEdit(category);
      setCatImg(category.categoryImg)
      setShowModal(true);
    }
  };
  
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  //get
  const fetchCategories = (page = 1, limit = 5) => {
    fetch(`${GET_CAT_API_BASE_URL}/getcategory/${page}?limit=${limit}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setCategories(data.result);
        setCurrentPage(data.page);
        setTotalPages(data.totalPages);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    fetchCategories(currentPage);
  }, [currentPage]);

  //delete 
  const deleteCategory = (id) => {
    console.log(id);
    fetch(`${GET_CAT_API_BASE_URL}/deleteByCatId/${id}`, {
      method: 'DELETE',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          console.log(data.status)
          toast.success(data.message || 'Category deleted successfully');


          const updatedProduct = categories.filter((item) => item._id !== data.result._id);
          setCategories(updatedProduct);



        } else {
          console.error(`Failed to delete category with ID ${id}`);
          toast.error(data.message || 'An error occurred while deleting the category');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error('An error occurred while processing your request');
      });
  };

  //sort
  const idWise = (sortBy) => {
    const sortedCategories = [...categories];
    const isDescending = sortBy === 'desc';

    sortedCategories.sort((a, b) => {
      const idA = parseFloat(a.id.match(/\d+/)[0]);
      const idB = parseFloat(b.id.match(/\d+/)[0]);

      if (isDescending) {
        return idB - idA;
      } else {
        return idA - idB;
      }
    });

    setCategories(sortedCategories);
    setIdSortDirection(isDescending ? 'asc' : 'desc');
  };

  const categoryNameWise = (sortBy) => {
    const sortedCategories = [...categories];
    const isDescending = sortBy === 'desc';

    sortedCategories.sort((a, b) => {
      if (isDescending) {
        return b.MaincategoryName.localeCompare(a.MaincategoryName);
      } else {
        return a.MaincategoryName.localeCompare(b.MaincategoryName);
      }
    });

    setCategories(sortedCategories);
    setCategoryNameSortDirection(isDescending ? 'asc' : 'desc');
  };

  // search

  const handleSearch = (e) => {
    e.preventDefault();
    const queryParams = [];

    if (searchQueryId) {
      queryParams.push(`id=${searchQueryId}`);
    }

    if (searchQueryName) {
      queryParams.push(`MaincategoryName=${searchQueryName}`);
    }


    const queryString = queryParams.join('&');
    const apiUrl = `${GET_CAT_API_BASE_URL}/searchcategory?${queryString}`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error('Data not Found.');
      });
  };

  const clearSearch = () => {
    setSearchQueryId('');
    setSearchQueryName('');
    fetchCategories();
  };


  // const clearSearch = () => {
  //   setSearchQueryId('');
  //   setSearchQueryName('');
  //   fetchCategories(); // Fetch all categories to reset the list
  // };


  const handleSelectCategory = (categoryId) => {
    console.log(categoryId)
    if (selectedCategories.includes(categoryId)) {

      setSelectedCategories((prevSelected) => prevSelected.filter(id => console.log(prevSelected)));
    } else {
      setSelectedCategories((prevSelected) => [...prevSelected, categoryId]);
    }
  };


  const handleDeleteSelected = () => {
    selectedCategories.forEach((categoryId) => {
      deleteCategory(categoryId);
    });
    setSelectedCategories([]);
  };


  return (
    <>

      <div className="flex flex-col main-container px-4 overflow-hidden text-sm ">
        <div className="">
          <div className="">
            <div className="flex justify-between items-center border-b mt-[4rem] md:mt-[1rem] sm:mt-[1rem]">
              <h1 className={`text-xl px-4 mb-4 category-title ${isDarkMode ? 'dark-mode ' : 'dashbg'} font-semibold`}>
                Category
              </h1>
              <Link>
                <button
                  className="bg-blue-500 text-white font-semibold px-6 py-2 rounded-lg mb-6 hover:bg-blue-600 focus:outline-none"
                  onClick={toggleModal}
                >
                  Add
                </button>
              </Link>
            </div>

            <div className="flex flex-col mt-[25px]">
              <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 py-14">
                <div className="align-middle inline-block w-full rounded-xl shadow-[0_3px_10px_rgb(0,0,0,0.2)] ">
                  <div className="grid grid-cols gap-2 py-3 px-4">


                    <form className="flex items-center space-x-4 py-3">
                    <input
                        type="text"
                        placeholder="Search by Name"
                        value={searchQueryName}
                        onChange={(e) => setSearchQueryName(e.target.value)}
                        className={`px-2 py-1 border rounded ${isDarkMode ? 'dark' : 'text-black'}`}
                      />
                      <button
                        className="bg-blue-500 text-white font-semibold px-2 py-2 rounded-lg hover:bg-blue-600 focus:outline-none"
                        onClick={handleSearch}
                    >
                        Search
                      </button>

                      <button
                        className="bg-red-600 text-white font-semibold px-3 py-2 rounded-lg hover:bg-red-700 focus:outline-none"
                        onClick={clearSearch}
                      >
                        Clear Search
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <nav className=" flex items-center flex-column flex-wrap md:flex-row justify-between  py- mt-10" aria-label="Table navigation">
              <span className="text-sm font-normal mb-4 md:mb-0 block w-full md:inline md:w-auto text-black text-center">
                Showing rows
                <span className="font-semibold font-semibold text-[#7B68EE] rounded-full bg-gray-300 px-1  ml-2 mr-1">
                  {(currentPage - 1) * 5 + 1}
                </span>
                to
                <span className="font-semibold font-semibold text-[#7B68EE] rounded-full bg-gray-300 px-1  ml-1">
                  {(currentPage - 1) * 5 + 5}
                </span>
              </span>


              <div className="py-4 pr-4 w-auto justify-content float-right">
                <ReactPaginate
                  previousLabel={"←"}
                  nextLabel={" →"}
                  pageCount={totalPages}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"pagination__link--active "}
                />
              </div>
            </nav>


            <div className="relative overflow-auto shadow-md">
              <div className="table-container overflow-auto max-h-[400px]">
                <table className="border min-w-full divide-y divide-gray-200 w-full text-sm text-left rtl:text-right text-gray-500 sticky top-0 bg-white ">
                  <thead className="sticky top-[-2px] text-center text-xs text-white bg-[#7B68EE]">
                    <tr className='text-center'>
                      <th scope="col" className="px-6 py-3 text-center ">
                        No

                      </th>
                      <th scope="col" className="px-6 py-3 text-center">

                        Id
                        <button
                          className="ml-2 text-white hover:text-white"
                          onClick={() => idWise(idSortDirection)}
                        >
                          {idSortDirection === 'asc' ? <FaCaretUp /> : <FaCaretDown />}
                        </button>
                     
                      </th>
                      <th scope="col" className="px-6 py-3">

                        Category Name
                        <button
                          className="ml-2 text-white hover:text-white"
                          onClick={() => categoryNameWise(categoryNameSortDirection)}
                        >
                          {categoryNameSortDirection === 'asc' ? <FaCaretUp /> : <FaCaretDown />}
                        </button>

                      </th>
                      <th>
                        <div className="flex items-center ml-4">
                          Image
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className='text-center bg-white border-b bg-white hover:border-gray-200 hover:bg-gray-50 hover:bg-white '>
                    {categories.length === 0 ? (
                      <tr>
                        <td colSpan="4" className="text-center py-4">
                          No categories found.
                        </td>
                      </tr>
                    ) : (
                      categories.map((el, i) => {
                        console.log(el)
                        return (
                          <tr className='text-center bg-white border-b bg-white border-gray-200 hover:bg-gray-50 hover:bg-white' key={i + 1}>
                            <td className="px-6 py-4">
                              {(currentPage - 1) * 5 + i + 1}
                            </td>

                            <td className="px-6 py-4">
                              <div className="leading-5 text-gray-900">
                                {el.id}
                              </div>
                            </td>

                            <td className="px-6 py-4 ">
                              <div className="leading-5 text-gray-900">
                                {el.MaincategoryName}
                              </div>
                            </td>

                            <td className="px-6 py-4 ">
                              <div className="leading-5 text-gray-900">
                                <img src={`https://ecommmerceapi.hindustanrides.in/uploads/${el.categoryImg}`} className='w-10 h-10 ' />
                              </div>
                            </td>

                            <td className="px-4 py-4 bg-white-200 text-center ">
                              <div className="leading-5 text-gray-900 flex space-x-6 justify-center items-center">
                                <FaEdit size={20} fill='green' onClick={() => editCategory(el.id)} className='cursor-pointer' />
                                <FaTrash size={20} fill='red' onClick={() => deleteCategory(el._id)} className='cursor-pointer' />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>


              </div>
            </div>

          </div>
          {showModal && (
            <CategoryModal
              isOpen={showModal}
              toggleModal={toggleModal}
              categories={categories}
              setCategories={setCategories}
              isEditMode={isEditMode}
              categoryToEdit={categoryToEdit}
              setCatImg={setCatImg}
              catImg={catImg}

            />
          )}
        </div>
      </div>
    </>
  );
};

export default Category;
